import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";
// import SelectWithSearch from "../form/SelectWithSearch";
import { useSelector } from "react-redux";
import DropDownCheckBoxWithNameValue from "../form/DropDownCheckBoxWithNameValue";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { courseListData, userListData } from "../../store/companiesSlice";
// import DropdownCheckbox from "../form/DropdownCheckbox";


const AddEditCompaniesModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = "ASC",
  setIsReset = () => { },
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [userId, setUserId] = useState(user?._id)
  const userList = useSelector((state) => state.companyListData.userList);
  const courseList = useSelector((state) => state.companyListData.courseList);

  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [selectUserValues, setSelectUserValues] = useState([])
  const [selectCourseValues, setSelectCourseValues] = useState([])
  const [userLimit, setUserLimit] = useState(null)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [idCode, setIdCode] = useState("")

  // const [selectValueFromDropDown, setSelectValueFromDropDown] = useState("")
  // const [selectCourseValueFromDropDown, setSelectCourseValueFromDropDown] = useState("")

  // course list hitting===========>
  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        const res = await postData('course/list', {});
        if (res.status === 200) {
          dispatch(courseListData(res.data))
        } else {
        }
      };

      loadData();
    }
  }, [user?._id, dispatch]);

  useEffect(() => {
    const loadData = async () => {
      const res = await postData("user/list", {
        type: "user",
        status: true
      });
      if (res.status === 200) {
        dispatch(userListData(res.data))
      } else {
      }
    };
    loadData();
  }, [user?._id, dispatch])


  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      setEmail(editData.email);
      setPhone(editData.phone);
      setSelectUserValues(editData.user_ids || [])
      setSelectCourseValues(editData.course_ids || [])
      setUserLimit(editData.user_limit || null);
      setFirstName(editData?.first_name ?? "");
      setLastName(editData?.last_name ?? "");
      setIdCode(editData?.id_code ?? "");

      // setPassword(editData.password);
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setDisabled(false);
    setEditData({});
    setFirstName("");
    setLastName("");
    setIdCode("");
    setSelectUserValues([])
    setSelectCourseValues([])
    setUserLimit(null)
  };

  const onSubmit = async () => {
    setDisabled(true);
    if (!userLimit) {
      toastr.error('Please set an user limit...')
      setDisabled(false);
      return
    }
    const res = await postData(`company/${editData?._id ? "edit" : "add"}`, {
      // table_name: 'user',
      password,
      name,
      email,
      user_ids: selectUserValues,
      course_ids: selectCourseValues,
      user_limit: userLimit || null,
      first_name: firstName || null,
      last_name: lastName || null,
      id_code: idCode || null,
      phone,
      type: "company",
      id: editData?._id || null,
      ordering: editData?._id ? editData.ordering : list.length,
    });

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data.map(item => ({
              ...item,
              user_ids: item.user_ids.filter(id => !selectUserValues.includes(id)),
              user_limit: userLimit
            })), res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data.map(item => ({
              ...item,
              user_ids: (item.user_ids || []).filter(id => !selectUserValues.includes(id)),
              user_limit: userLimit
            }))],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id === editData._id ? {
              ...item,
              ...res.data,
              user_ids: selectUserValues,
              user_limit: userLimit
            } : item
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
      if (res?.reset) {
        setIsReset(prev => !prev);
      }
    } else {
      if (res?.reset) {
        // closeModal();
        toastr.options.timeOut = 4000;
      }
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  const updateHandler = (id, checked) => {
    setSelectUserValues((users) => {
      const findUser = users.find(user => user === id)
      if (findUser) return users.filter(user => user !== id)
      return [...users, id]
    });
  }

  const updateCourseDataHandler = (id, checked) => {
    setSelectCourseValues((courses) => {
      const findUser = courses.find(course => course === id)
      if (findUser) return courses.filter(course => course !== id)
      return [...courses, id]
    });
  }


  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
      modalSize={"xl"}
    >
      <div className="relative w-full">
        <div className="relative w-full mb-3">
          <Input
            label={"Name"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Add Company's Name"}
            inputValue={name}
            setInput={setName}
          />
        </div>
        <div className="grid sm:grid-cols-2 gap-x-4">
          <div className="relative w-full mb-3">
            <Input
              label={"Email"}
              labelClasses={"!text-xs"}
              inputType={"email"}
              inputPlaceholder={"Add Email"}
              inputValue={email}
              setInput={setEmail}
            />
          </div>
          <div className="relative w-full mb-3">
            <Input
              label={"Phone"}
              labelClasses={"!text-xs"}
              inputType={"number"}
              inputPlaceholder={"Add Phone No."}
              inputValue={phone}
              setInput={setPhone}
            />
          </div>
          <div className="relative w-full mb-3">
            <Input
              label={"User Limit"}
              labelClasses={"!text-xs"}
              inputType={"number"}
              inputPlaceholder={"Enter User Limit"}
              inputValue={userLimit}
              reGx={true}
              setInput={(val) => {
                if (Number(val) >= 0) {
                  setUserLimit(val)
                }
              }}
            />
          </div>
          {
            Number(userLimit) === 0 || (Number(userLimit) >= 2) ? (
              <div className="relative w-full mb-3">
                <DropDownCheckBoxWithNameValue
                  data={userList.map((item) => ({
                    ...item,
                    isEnabled: (selectUserValues || []).includes(item._id)
                  }))}
                  functions={(id, event) => updateHandler(id, event)}
                  count={selectUserValues?.length > 0 && userList?.length > 0 ? selectUserValues.filter(id => (
                    userList.map(item => item._id).includes(id)
                  )) : []}
                  label={"Select User"}
                  labelClasses={"!text-xs"}
                />

              </div>
            ) : (
              <></>
            )
          }

          <div className="relative w-full mb-3">
            <DropDownCheckBoxWithNameValue
              data={courseList?.map((item) => ({
                ...item,
                isEnabled: selectCourseValues?.includes(item._id),
                name: item.title.en
              }))}
              functions={(id, event) => updateCourseDataHandler(id, event)}
              count={selectCourseValues?.length > 0 && courseList?.length > 0 ? selectCourseValues.filter(id => (
                courseList.map(item => item._id).includes(id)
              )) : []}
              label={"Select Course"}
              labelClasses={"!text-xs"}
            />
          </div>
          {
            Number(userLimit) > 0 && (Number(userLimit) < 2) ? (
              <>
                <div className="relative w-full mb-3">
                  <Input
                    label={"First Name"}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={"Enter User First Name"}
                    inputValue={firstName}
                    reGx={true}
                    setInput={(val) => {
                      setFirstName(val)
                    }}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <Input
                    label={"Last Name"}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={"Enter User Last Name"}
                    inputValue={lastName}
                    reGx={true}
                    setInput={(val) => {
                      setLastName(val)
                    }}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <Input
                    label={"id code"}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={"Enter User id code"}
                    inputValue={idCode}
                    reGx={true}
                    setInput={(val) => {
                      if (val !== " ") {
                        setIdCode(val)
                      }
                    }}
                  />
                </div>
              </>
            ) : <></>
          }

        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditCompaniesModal;
