import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import TableAlt from '../components/elements/table/TableAlt';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';
import toastr from 'toastr';
import { API_URL } from '../config/host';
import { setTitle } from '../helpers/MetaTag';
import EditPages from '../components/modals/EditPages';

const Pages = () => {
  setTitle('Radisol | Pages');
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [modalType, setModalType] = useState('')


  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });

  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: 'Active', type: 'checkbox', isChecked: false, value: 1 },
    { _id: 2, name: 'Inactive', type: 'checkbox', isChecked: false, value: 0 },
  ]);
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);


  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          setOffset(0)
          setLimit(10)
          searchParams.keyword = keyword;
        }
        if (statusFilterData.filter((item) => item.touched && item.isChecked).length) {
          searchParams.status = { $in: statusFilterData.filter((item) => item.touched && item.isChecked).map((item) => item.value) }

        }

        const res = await postData('page/list', {
          deleted_at: null,
          ...searchParams,
          sortQuery: sortField && sortType ? { [sortField]: sortType } : { ordering: 1 }||"",
          limit: limit,
          offset: offset,
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.result,
            loading: false,
            count: res.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [user?._id, keyword, limit, offset, sortField, sortType, statusFilterData.filter((item) => item.touched && item.isChecked).length]);

  const tableHeadData = [
    {
      _id: 2,
      width: 15,
      title: 'name',
      name: 'name',
      align: 'left',
      sortable: true,
      filterable: false,
      langVal: true
    },

    {
      _id: 3,
      width: 15,
      title: 'alias',
      name: 'alias',
      align: 'left',
      sortable: false,
      filterable: false,
    },
    {
      _id: 6,
      width: 15,
      title: 'description',
      name: 'description',
      align: 'left',
      sortable: false,
      filterable: false,
      html: true,
      langVal: true
    },
    {
      _id: 7,
      action: true,
    },
  ];



  const changeStatus = async (id) => {
    const res = await postData('crud/statusChange', {
      table_name: 'page',
      id: [id],
      column_name: 'status',
      column_value: !(
        list.data.find((item) => item._id === id).status || false
      ),
    });

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
              ...item,
              status: !(
                list.data.find((item) => item._id === id).status || false
              ),
            }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };


  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'button',
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
  ];

  const onDrag = async (data = []) => {
    const res = await postData('crud/ordering', {
      table_name: 'page',
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
    });

    if (res.status !== 200) {
      toastr.error(res.message);
    } else {
      toastr.success(res.message);
    }
  };


  return (
    <>
      <ListLayout
        title='Pages'
        addName='Add '
        showAddButton={false}
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={true}
          onDrag={onDrag}
          functions={changeStatus}
        />
      </ListLayout>
      <EditPages
        show={showModal}
        setShow={setShowModal}
        hasImageField={(editData?.alias === "how-to-order" || editData?.alias === "muuda-oma-kiirgustegevuse") && true}
        title={
          Object.keys(editData).length > 0
            ? 'Edit Page'
            : 'Add Page'
        }
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default Pages;
