import { Route, Routes, BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import FreeAuthRoute from './routes/FreeAuthRoute';
import ProtectedRoute from './routes/ProtectedRoute';

import AuthLayout from './layouts/AuthLayout';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import DefaultLayout from './layouts/DefaultLayout';
import Dashboard from './pages/Dashboard';
// import Settings from './pages/Settings';
import AdminProfile from './pages/AdminProfile';
//CMS
import EmailTemplates from './pages/EmailTemplates';
import ManageEmailTemplate from './pages/ManageEmailTemplate';

//CRM
import UserManage from './pages/UserManage';
import Users from './pages/Users';
import PasswordCreate from './pages/PasswordCreate';
import Notification from './pages/Notification';
import Categories from './pages/Categories';
import Tags from './pages/Tags';
import Enquiry from './pages/Enquiry';
import Course from './pages/Course';
import Quiz from './pages/Quiz';
import AddEditCategory from './pages/AddEditCategory';
import Instractor from './pages/Instractor';

import ManageCourse from './pages/ManageCourse';
import ManageCurriculum from './pages/ManageCurriculum';
import Testimonials from './pages/Testimonials';

import CourseGuides from './pages/CourseGuides';
import Pages from './pages/Pages';
import SiteSettings from './pages/SiteSettings';
import Quizeresult from './pages/Quizeresult';
import Banner from './pages/Banner';
import UserDetails from "./pages/UserDetails";
import Activity from './pages/Activity';
import VerifyResetPassword from './pages/VerifyResetPassword';
import ResetPassword from './pages/ResetPassword';
import CourseCompleted from './pages/CourseCompleted';

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<AuthLayout />}>
            <Route
              path='/'
              exact
              element={
                <FreeAuthRoute>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/signin' element={<AuthLayout />}>
            <Route
              path='/signin'
              exact
              element={
                <FreeAuthRoute>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/signup' element={<AuthLayout />}>
            <Route
              path='/signup'
              exact
              element={
                <FreeAuthRoute>
                  <Signup />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/forgot-password' element={<AuthLayout />}>
            <Route
              path='/forgot-password'
              exact
              element={
                <FreeAuthRoute>
                  <ForgotPassword />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/verify-reset-password/:hash' element={<AuthLayout />}>
            <Route
              path='/verify-reset-password/:hash'
              element={
                <FreeAuthRoute>
                  <VerifyResetPassword />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/reset-password/:hash' element={<AuthLayout />}>
            <Route
              path='/reset-password/:hash'
              element={
                <FreeAuthRoute>
                  <ResetPassword />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/manage' element={<AuthLayout />}>
            <Route
              path='/manage'
              exact
              element={
                <FreeAuthRoute>
                  <PasswordCreate />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/dashboard' element={<DefaultLayout />}>
            <Route
              path='/dashboard'
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/banner' element={<DefaultLayout />}>
            <Route
              path='/banner'
              exact
              element={
                <ProtectedRoute>
                  <Banner />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/site-settings' element={<DefaultLayout />}>
            <Route
              path='/site-settings'
              exact
              element={
                <ProtectedRoute>
                  <SiteSettings />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* CMS Part  */}
          <Route path='/email-templates' element={<DefaultLayout />}>
            <Route
              path='/email-templates'
              exact
              element={
                <ProtectedRoute>
                  <EmailTemplates />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/edit-email/:id' element={<DefaultLayout />}>
            <Route
              path='/edit-email/:id'
              exact
              element={
                <ProtectedRoute>
                  <ManageEmailTemplate />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* CRM Part  */}
          <Route path='/user/add' element={<DefaultLayout />}>
            <Route
              path='/user/add'
              exact
              element={
                <ProtectedRoute>
                  <UserManage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/users" element={<DefaultLayout />}>
            <Route
              path="/users"
              exact
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/user-details/:id" element={<DefaultLayout />}>
            <Route
              path="/user-details/:id"
              exact
              element={
                <ProtectedRoute>
                  <UserDetails />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/admin" element={<DefaultLayout />}>
            <Route
              path="/admin"
              exact
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/companies" element={<DefaultLayout />}>
            <Route
              path="/companies"
              exact
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/activity" element={<DefaultLayout />}>
            <Route
              path="/activity"
              exact
              element={
                <ProtectedRoute>
                  <Activity />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/categories' element={<DefaultLayout />}>
            <Route
              path='/categories'
              exact
              element={
                <ProtectedRoute>
                  <Categories />
                </ProtectedRoute>
              }
            />
          </Route>


          <Route path="/add-category" element={<DefaultLayout />}>
            <Route
              path='/add-category'
              exact
              element={
                <ProtectedRoute>
                  <AddEditCategory />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-category/:id" element={<DefaultLayout />}>
            <Route
              path='/edit-category/:id'
              exact
              element={
                <ProtectedRoute>
                  <AddEditCategory />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/tags' element={<DefaultLayout />}>
            <Route
              path='/tags'
              exact
              element={
                <ProtectedRoute>
                  <Tags />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/enquiry' element={<DefaultLayout />}>
            <Route
              path='/enquiry'
              exact
              element={
                <ProtectedRoute>
                  <Enquiry />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/courses' element={<DefaultLayout />}>
            <Route
              path='/courses'
              exact
              element={
                <ProtectedRoute>
                  <Course />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/edit-course/:id' element={<DefaultLayout />}>
            <Route
              path='/edit-course/:id'
              exact
              element={
                <ProtectedRoute>
                  <ManageCourse />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/curriculums/:id' element={<DefaultLayout />}>
            <Route
              path='/curriculums/:id'
              exact
              element={
                <ProtectedRoute>
                  <ManageCurriculum />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/instructors' element={<DefaultLayout />}>
            <Route
              path='/instructors'
              exact
              element={
                <ProtectedRoute>
                  <Instractor />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/pages' element={<DefaultLayout />}>
            <Route
              path='/pages'
              exact
              element={
                <ProtectedRoute>
                  <Pages />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/testimonials' element={<DefaultLayout />}>
            <Route
              path='/testimonials'
              exact
              element={
                <ProtectedRoute>
                  <Testimonials />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/quizzes' element={<DefaultLayout />}>
            <Route
              path='/quizzes'
              exact
              element={
                <ProtectedRoute>
                  <Quiz />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/quiz-results' element={<DefaultLayout />}>
            <Route
              path='/quiz-results'
              exact
              element={
                <ProtectedRoute>
                  <Quizeresult />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/course-guides' element={<DefaultLayout />}>
            <Route
              path='/course-guides'
              exact
              element={
                <ProtectedRoute>
                  <CourseGuides />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/course-completed' element={<DefaultLayout />}>
            <Route
              path='/course-completed'
              exact
              element={
                <ProtectedRoute>
                  <CourseCompleted />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/edit/user/:id' element={<DefaultLayout />}>
            <Route
              path='/edit/user/:id'
              exact
              element={
                <ProtectedRoute>
                  <UserManage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/profile' element={<DefaultLayout />}>
            <Route
              path='/profile'
              exact
              element={
                <ProtectedRoute>
                  <AdminProfile />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/notification' element={<DefaultLayout />}>
            <Route path='/notification' exact element={<Notification />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;