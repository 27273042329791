import { useState, useEffect, useRef } from 'react';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import Input from '../form/Input';
import Textarea from '../form/Textarea';
import Image from '../elements/Image';
import Select from '../form/Select';
import Checkbox from '../form/Checkbox';
import { Editor } from '@tinymce/tinymce-react';
import ButtonFile from '../form/ButtonFile';
import { postData } from '../../services/api';
import { convertToBase64Async } from '../../helpers';
import toastr from 'toastr';
import { tinymceApiKey, tinyMceInit } from '../../helpers';
import DropdownCheckbox from '../form/DropdownCheckbox';

const AddEditCourseModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = "ASC",
}) => {
  const editorRef = useRef(null);
  const [disabled, setDisabled] = useState();
  const [code, setCode] = useState();
  const [content, setContent] = useState();
  const [subject, setSubject] = useState();
  const [fromEmail, setFromEmail] = useState();
  const [toEmail, setToEmail] = useState();
  const [ccEmail, setCcEmail] = useState();


  // const handleChange = (i, e) => {
  //   let newFormValues = [...formValues];
  //   newFormValues[i][e.target.name] = e.target.value;
  //   setFormValues(newFormValues);
  // };

  // const handleSelect = (i, e) => {
  //   let newFormValues = [...formValues];
  //   newFormValues[i]["ingredient"] = e;
  //   setFormValues(newFormValues);
  // };

  console.log(editData)

  useEffect(() => {
    if (editData?._id) {
      setSubject(editData.subject);
      setContent(editData.content);
      setToEmail(editData?.to_email);
      setFromEmail(editData?.from_mail);
      setCcEmail(editData?.cc_email);
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setCode("");
    setContent("");
    setToEmail('');
    setFromEmail('');
    setCcEmail('');
    setDisabled(false);
    setEditData({});
  };


  const onSubmit = async () => {
    setDisabled(true)

    const res = await postData(`crud/${editData?._id ? 'edit' : 'add'}`, {
      table_name: 'emailTemplate',
      data: {
        "code": code,
        "content": content,
        "subject": subject,
        "from_mail": fromEmail,
        "to_email": toEmail,
        "cc_email": ccEmail,
        id: editData?._id || null,
        ordering: editData?._id ? editData.ordering : list.length
      },
      validationRules: {
        // 'data.question': 'required',
        // 'data.answer': 'required'
      },
      validationMessages: {
        // 'required.data.question': 'Question Field is required',
        // 'required.data.answer': 'Answer Field is required',
      }
    })

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList(list => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1
          }))
        } else {
          setList(list => ({
            ...list,
            data: [res.data, ...list.data,],
            count: list.count + 1
          }))
        }
      } else if (editData && editData?._id) {
        setList(list => ({
          ...list,
          data: list.data.map(item => item._id !== editData._id ? item : res.data)
        }))
      }

      closeModal()
      toastr.success(res.message)
    } else {
      setDisabled(false)
      toastr.error(res.message)
    }
  }



  // const updateHandler = async (id, checked) => {
  //   console.log(id, checked)
  //   setTags(
  //     tags.map((it) => {
  //       if (it?._id === id) {
  //         return {
  //           ...it,
  //           isEnabled: checked,
  //         }
  //       } else {
  //         return it;
  //       }
  //     })
  //   );
  // }


  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xxxxxl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <div className='flex justify-between w-full'>
        <div className='relative w-full'>
          <div className="grid md:grid-cols-3 gap-x-4">
            <div className="relative">
              <div className="relative mb-3">
                <Input
                  label={"Subject"}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={"Enter Subject"}
                  inputValue={subject}
                  setInput={setSubject}
                />
              </div>
              <div className="relative mb-3">
                <Input
                  label={"From Email"}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={"Enter From Email"}
                  inputValue={fromEmail}
                  setInput={setFromEmail}
                />
              </div>
              <div className="relative mb-3">
                <Input
                  label={"To Email"}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={"Enter To Email"}
                  inputValue={toEmail}
                  setInput={setToEmail}
                />
              </div>
              {/* <div className="relative mb-3">
                <Input
                  label={"CC Email"}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={"Enter CC Email"}
                  inputValue={ccEmail}
                  setInput={setCcEmail}
                />
              </div> */}
            </div>
            <div className="relative col-span-2">
              <div className="relative mb-3">
                <label className='text-xs font-medium text-slate-500 mb-1'>Content</label>
                <Editor
                  apiKey={tinymceApiKey()}
                  onInit={(evt, editor) => editorRef.current = editor}
                  value={content}
                  init={{ height: 500 }}
                  onEditorChange={(newText) => setContent(newText)}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditCourseModal;
