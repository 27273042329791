import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import ButtonFile from "../components/form/ButtonFile";
import Input from "../components/form/Input";
import { API_URL, DIGI_IMG_URL } from "../config/host";


import Textarea from "../components/form/Textarea";
import toastr from "toastr";


import "flatpickr/dist/flatpickr.css";
import { connect, useDispatch } from "react-redux";

import { postData } from "../services/api";
import { useSelector } from "react-redux";
import { siteSettingsImage } from "../store/settingsSlice";




const Settings = ({ siteState, siteLogo }) => {
  setTitle("Radisol | Site Settings");


  const user = useSelector((state) => state.auth.user);
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [facebooklink, setFacebookLink] = useState('')
  const [linkedinlink, setLinkedLink] = useState('')

  const [title, setCompanyTitle] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [offset, setOffset] = useState('')
  const [phoneno, setPhoneNo] = useState('')

  const [copyright, setCopyRight] = useState("");
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");

  const [logoId, setLogoId] = useState(null);
  const [favId, setFavId] = useState(null);

  const [siteIcon, setSiteIcon] = useState(logo)


  const [SiteSettingsDetails, setSiteSettingsDetails] = useState({})
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });

  const [supportTitle, setSupportTitle] = useState('')
  const [supportEmail, setsupportEmail] = useState('')
  const [supportPhone, setSupportPhone] = useState('')
  const [supportAddress, setsupportAddress] = useState('')
  const [subscribeText, setSubscribeText] = useState('')


  const Obj = { supportTitle, supportEmail, supportPhone, supportAddress }


  const [contactTitle, setContactTitle] = useState('')
  const [contactAddress, setContactAddress] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const ContactAddressObj = { contactTitle, contactEmail, contactPhone, contactAddress }


  const [officeTitle, setOfficeTitle] = useState('')
  const [officeAddress, setOfficeAddress] = useState('')
  const [officePhone, setOfficePhone] = useState('')
  const [officeEmail, setOfficeEmail] = useState('')
  const OfficeaddressObj = { officeTitle, officeAddress, officePhone, officeEmail }

  const [isEmpty, setIsEmpty] = useState(true)


  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link)
        ;
    }
    link.href = favicon;
  }, [favicon])

  useEffect(() => {

    if (user?._id) {
      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));




        const res = await postData('crud/list', {
          table_name: 'siteSettings',
          queryParams: {
            deleted_at: null,


          },
          selectParams: {
            name: true,
            email: true,
            logo_id: true,
            favicon_id: true,
            copyright: true,
            facebook_link: true,
            linkedin_link: true,
            contact_address: true,
            office_address: true,
            customer_support_address: true,
            phone_code: true,
            phone_no: true,
            customer_support_address: true,
            contact_address: true,
            office_address: true,
            logo: {
              $cond: [
                { $arrayElemAt: ['$logo.path', 0] },
                {
                  $concat: [
                    DIGI_IMG_URL,
                    { $arrayElemAt: ['$logo.path', 0] },
                  ]
                },
                null
              ]
            },
            favicon: {
              $cond: [
                { $arrayElemAt: ['$favicon.path', 0] },
                {
                  $concat: [
                    DIGI_IMG_URL,
                    { $arrayElemAt: ['$favicon.path', 0] },
                  ]
                },
                null
              ]
            }
          },
          join: [
            {
              toTable: "files",
              fromField: "logo_id",
              toField: "_id",
              selectField: "logo",
              projection: {
                path: true,
              },
            },
            {
              toTable: "files",
              fromField: "favicon_id",
              toField: "_id",
              selectField: "favicon",
              projection: {
                path: true,
              },
            },

          ],
          limit: 1,
          offset: offset,
        });
        if (res.status === 200) {
          if (res.data.list.length !== 0) {
            setIsEmpty(false)
          } else {
            setIsEmpty(true)
          }
          setSiteSettingsDetails(res.data.list[0])
          setEmail(res.data.list.length !== 0 ? res.data.list[0].email : null)
          setName(res.data.list.length !== 0 ? res.data.list[0].name : null)
          setCopyRight(res.data.list.length !== 0 ? res.data.list[0].copyright : null)
          setFacebookLink(res.data.list.length !== 0 ? res.data.list[0].facebook_link : null)
          setLinkedLink(res.data.list.length !== 0 ? res.data.list[0].linkedin_link : null)
          setLogo(res.data.list.length !== 0 ? res.data.list[0].logo : null)
          setFavicon(res.data.list.length !== 0 ? res.data.list[0].favicon : null)
          setId(res.data.list.length !== 0 ? res.data.list[0]._id : null)
          setSupportTitle(res.data.list.length !== 0 ? res.data.list[0].customer_support_address.supportTitle : null)
          setsupportEmail(res.data.list.length !== 0 ? res.data.list[0].customer_support_address.supportEmail : null)
          setsupportAddress(res.data.list.length !== 0 ? res.data.list[0].customer_support_address.supportAddress : null)
          setSupportPhone(res.data.list.length !== 0 ? res.data.list[0].customer_support_address.supportPhone : null)
          setContactTitle(res.data.list.length !== 0 ? res.data.list[0].contact_address.contactTitle : null)
          setContactEmail(res.data.list.length !== 0 ? res.data.list[0].contact_address.contactEmail : null)
          setContactAddress(res.data.list.length !== 0 ? res.data.list[0].contact_address.contactAddress : null)
          setContactPhone(res.data.list.length !== 0 ? res.data.list[0].contact_address.contactPhone : null)

          setOfficeTitle(res.data.list.length !== 0 ? res.data.list[0].office_address.officeTitle : null)
          setOfficeAddress(res.data.list.length !== 0 ? res.data.list[0].office_address.officeAddress : null)
          setOfficeEmail(res.data.list.length !== 0 ? res.data.list[0].office_address.officeEmail : null)
          setOfficePhone(res.data.list.length !== 0 ? res.data.list[0].office_address.officePhone : null)




        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };
      loadData();
    }
  }, []);

  const handelSubmit = async (e) => {
    setLoading(true);
    const formData1 = new FormData()
    let logoId = undefined;
    if (logo instanceof File) {
      if (SiteSettingsDetails?.logo_id) {
        formData1.append('id', SiteSettingsDetails?.logo_id)
      }

      formData1.append('file', logo)
      const imgRes = await postData('file/upload', formData1, 'multipart/form-data')
      if (imgRes.status === 200) {
        logoId = imgRes.data?._id;

      } else {
        setLoading(false)
        toastr.error(imgRes.message)
      }
    }

    let faviconId = undefined;
    if (favicon instanceof File) {
      const formData2 = new FormData()
      if (SiteSettingsDetails?.favicon_id) {
        formData2.append('id', SiteSettingsDetails?.favicon_id)
      }
      const favRes = await postData('file/upload', formData2, 'multipart/form-data')
      if (favRes.status === 200) {
        faviconId = favRes.data?._id
      } else {
        setLoading(false)
        toastr.error(favRes.message)
      }
    }

    if (id) {
      try {

        const res = await postData(`/crud/edit`, {
          table_name: 'siteSettings',
          data: {
            name: name,
            email: email,
            copyright: copyright,
            id: id,
            linkedin_link: linkedinlink,
            facebook_link: facebooklink,
            logo_id: logoId,
            favicon_id: faviconId,
            customer_support_address: Obj,
            contact_address: ContactAddressObj,
            office_address: OfficeaddressObj,
            subscribe_text:subscribeText
          },
          validationRules: {
            'data.name': 'required',
            // 'data.description': 'required'
          },
          validationMessages: {
            'required.data.name': 'name Field is required',
            // 'required.data.description': 'Description Field is required',
          }





        });

        if (res.status === 200) {
          setLoading(false);
          siteLogo(siteIcon)
          toastr.success(res.message);
        } else {
          toastr.error(res.message);
        }
      } catch (error) {
        setLoading(false);
        toastr.error(error.message);
      }
    } else {
      try {

        const res = await postData(`/crud/add`, {
          table_name: 'siteSettings',
          data: {
            name: name,
            email: email,
            copyright: copyright,
            linkedin_link: linkedinlink,
            facebook_link: facebooklink,
            subscribe_text: subscribeText,
            logo_id: logoId,
            favicon_id: faviconId,
            customer_support_address: Obj,
            contact_address: ContactAddressObj,
            office_address: OfficeaddressObj
          },
          validationRules: {
            'data.name': 'required',
            // 'data.description': 'required'
          },
          validationMessages: {
            'required.data.name': 'name Field is required',
            // 'required.data.description': 'Description Field is required',
          }





        });

        if (res.status === 200) {
          setLoading(false);
          toastr.success(res.message);
        } else {
          toastr.error(res.message);
        }
      } catch (error) {
        setLoading(false);
        toastr.error(error.message);
      }

    }

  };

  const onLogoChange = async (e) => {

    if (e.target.files.length) {
      setLogo(e.target.files[0]);
      setSiteIcon(e.target.files[0])
    }


  };

  const onFavChange = async (e) => {
    if (e.target.files.length) {
      setFavicon(e.target.files[0]);
    }

  };
  const handelCancel = () => {
    if (id) {
      setCompanyTitle(SiteSettingsDetails?.title)
      setDescription(SiteSettingsDetails?.description)
      setEmail(SiteSettingsDetails?.email)
      setPhone(SiteSettingsDetails?.phone)
      setAddress(SiteSettingsDetails?.address)
      setCopyRight(SiteSettingsDetails?.copyright)
      setLogo(SiteSettingsDetails?.logo)
      setFavicon(SiteSettingsDetails?.favicon)
      setName(SiteSettingsDetails?.name)
      setFacebookLink(SiteSettingsDetails?.facebook_link)
      setLinkedLink(SiteSettingsDetails?.linkedin_link)
      setSupportTitle(SiteSettingsDetails?.customer_support_address.supportTitle)

      setsupportAddress(SiteSettingsDetails?.customer_support_address.supportAddress)
      setsupportEmail(SiteSettingsDetails?.customer_support_address.supportEamil)
      setSupportPhone(SiteSettingsDetails?.customer_support_address.supportPhone)
      setContactTitle(SiteSettingsDetails?.contact_address.contactTitle)
      setContactAddress(SiteSettingsDetails?.contact_address.contactAddress)
      setContactEmail(SiteSettingsDetails?.contact_address.contactEmail)
      setContactPhone(SiteSettingsDetails?.contact_address.contactPhone)

      setOfficeTitle(SiteSettingsDetails?.office_address.officeTitle)
      setOfficeAddress(SiteSettingsDetails?.office_address.officeAddress)
      setOfficeEmail(SiteSettingsDetails.office_address.officeEmail)
      setOfficePhone(SiteSettingsDetails.office_address.officePhone)




    }
    else {

      setCompanyTitle("");
      setDescription("");
      setEmail("");
      setPhone("");
      setAddress("");
      setCopyRight("");
      setLogo("");
      setFavicon("")
      setLogoId(null)
      setFavId(null)
    }

  }


  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">Settings</div>
      </div>
      <div className="relative flex gap-4 ">
        <div className="w-[255px] space-y-4">

          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-6 px-6 flex flex-col space-y-4 relative">
              <div className="w-full h-52 overflow-hidden">
                {logo && logo instanceof File ? (
                  <Image
                    src={URL.createObjectURL(logo)}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                    id={"image1"}
                  />
                ) : (
                  <Image
                    src={logo}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                    id={"image1"}
                  />
                )}
              </div>
            </div>
            <div className="flex overflow-hidden rounded-b">
              <ButtonFile
                buttonType={"button"}
                buttonIcon={
                  false ? "fa-light fa-spinner fa-spin" : "fa-light fa-image"
                }
                buttonIconPosition={"left"}
                buttonLabel={"Change Logo"}
                buttonClasses={
                  "!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white h-10 !border-0"
                }
                functions={onLogoChange}
                accepts={"image/*"}
              />
            </div>
          </div>
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-6 px-6 flex flex-col space-y-4 relative">
              <div className="w-full h-52 overflow-hidden">
                {favicon && favicon instanceof File ? (
                  <Image
                    src={URL.createObjectURL(favicon)}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                    id={"image2"}
                  />
                ) : (
                  <Image
                    src={favicon}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                    id={"image2"}
                  />
                )}
              </div>
            </div>
            <div className="flex overflow-hidden rounded-b">
              <ButtonFile
                buttonType={"button"}
                buttonIcon={
                  false ? "fa-light fa-spinner fa-spin" : "fa-light fa-image"
                }
                buttonIconPosition={"left"}
                buttonLabel={"Change Favicon"}
                buttonClasses={
                  "!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white h-10 !border-0"
                }
                functions={onFavChange}
                accepts={"image/*"}
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="relative bg-white rounded shadow">
            <div className="grid xl:grid-cols-2">
              <div className="py-8 px-6 flex flex-col space-y-4">
                <div className="text-xl font-bold text-slate-900">
                  Site Settings
                </div>
                <div className="grid sm:grid-cols-2 gap-x-4">
                  <div className="relative mb-3">
                    <Input
                      label={"Title"}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={"Enter Site Title"}
                      inputValue={name}
                      setInput={setName}
                    />
                  </div>
                  <div className="relative mb-3">
                    <Input
                      label={"Email"}
                      labelClasses={"!text-xs"}
                      inputType={"email"}
                      inputPlaceholder={"Enter Email Id"}
                      inputValue={email}
                      setInput={setEmail}
                    />
                  </div>
                  <div className="relative mb-3">
                    <Input
                      label={"@Copyright"}
                      labelClasses={"!text-xs"}
                      inputPlaceholder={"Enter Copyright"}
                      inputValue={copyright}
                      setInput={setCopyRight}
                    />
                  </div>
                  {/* <div className="relative mb-3">
                    <Input
                      label={"SubScribe Text"}
                      labelClasses={"!text-xs"}
                      inputPlaceholder={"Enter SubScribe Text"}
                      inputValue={subscribeText}
                      setInput={setSubscribeText}
                    />
                  </div> */}
                  <div className="relative mb-3">
                    <Input
                      label={"Facebook Link"}
                      labelClasses={"!text-xs"}
                      inputType={"link"}
                      inputPlaceholder={"Enter facebook link"}
                      inputValue={facebooklink}
                      setInput={setFacebookLink}
                    />
                  </div>

                  <div className="relative mb-3 sm:col-span-2">
                    <Input
                      label={"Linkedin Link"}
                      labelClasses={"!text-xs"}
                      inputType={"link"}
                      inputPlaceholder={"Enter linkedin link"}
                      inputValue={linkedinlink}
                      setInput={setLinkedLink}
                    />
                  </div>
                </div>
              </div>
              <div className="py-8 px-6 flex flex-col space-y-4 border-t border-l-0 xl:border-t-0 xl:border-l border-slate-200">
                <div className="text-xl font-bold text-slate-900">
                  Customer Support
                </div>
                <div className="grid sm:grid-cols-2 gap-x-4">
                  <div className="relative mb-3 sm:col-span-2">
                    <Input
                      label={" Title"}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={"Enter Title"}
                      inputValue={supportTitle}
                      setInput={setSupportTitle}
                    />
                  </div>
                  <div className="relative mb-3">
                    <Input
                      label={" Email"}
                      labelClasses={"!text-xs"}
                      inputType={"email"}
                      inputPlaceholder={"Enter Email"}
                      inputValue={supportEmail}
                      setInput={setsupportEmail}
                    />
                  </div>
                  <div className="relative mb-3">
                    <Input
                      label={"Phone no."}
                      labelClasses={"!text-xs"}
                      inputPlaceholder={"Enter Phone no."}
                      inputValue={supportPhone}
                      setInput={setSupportPhone}
                    />
                  </div>
                  <div className="relative mb-3 sm:col-span-2">
                    <Input
                      label={"Address"}
                      labelClasses={"!text-xs"}
                      inputPlaceholder={"Enter  address"}
                      inputValue={supportAddress}
                      setInput={setsupportAddress}
                    />
                  </div>
                </div>
              </div>
              <div className="py-8 px-6 flex flex-col space-y-4 border-t border-slate-200">
                <div className="text-xl font-bold text-slate-900">
                  Contact Address
                </div>
                <div className="grid sm:grid-cols-2 gap-x-4">
                  <div className="relative mb-3 sm:col-span-2">
                    <Input
                      label={"Address Title"}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={"Set Title"}
                      inputValue={contactTitle}
                      setInput={setContactTitle}
                    />
                  </div>
                  <div className="relative mb-3">
                    <Input
                      label={"Contact Email"}
                      labelClasses={"!text-xs"}
                      inputType={"email"}
                      inputPlaceholder={"Enter Email "}
                      inputValue={contactEmail}
                      setInput={setContactEmail}
                    />
                  </div>
                  <div className="relative mb-3">
                    <Input
                      label={" Phone no."}
                      labelClasses={"!text-xs"}
                      inputPlaceholder={"Enter Phone"}
                      inputValue={contactPhone}
                      setInput={setContactPhone}
                    />
                  </div>
                  <div className="relative mb-3 sm:col-span-2">
                    <Input
                      label={" Address"}
                      labelClasses={"!text-xs"}
                      inputPlaceholder={"Enter address"}
                      inputValue={contactAddress}
                      setInput={setContactAddress}
                    />
                  </div>
                </div>
              </div>
              <div className="py-8 px-6 flex flex-col space-y-4 border-t border-l-0 xl:border-l border-slate-200">
                <div className="text-xl font-bold text-slate-900">
                  Office Address
                </div>
                <div className="grid sm:grid-cols-2 gap-x-4">
                  <div className="relative mb-3 sm:col-span-2">
                    <Input
                      label={"Address Title"}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={"Set Title"}
                      inputValue={officeTitle}
                      setInput={setOfficeTitle}
                    />
                  </div>
                  <div className="relative mb-3">
                    <Input
                      label={"Contact Email"}
                      labelClasses={"!text-xs"}
                      inputType={"email"}
                      inputPlaceholder={"Enter Email "}
                      inputValue={officeEmail}
                      setInput={setOfficeEmail}
                    />
                  </div>
                  <div className="relative mb-3">
                    <Input
                      label={" Phone no."}
                      labelClasses={"!text-xs"}
                      inputPlaceholder={"Enter Phone"}
                      inputValue={officePhone}
                      setInput={setOfficePhone}
                    />
                  </div>
                  <div className="relative mb-3 sm:col-span-2">
                    <Input
                      label={" Address"}
                      labelClasses={"!text-xs"}
                      inputPlaceholder={"Enter address"}
                      inputValue={officeAddress}
                      setInput={setOfficeAddress}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonType={"button"}
                  buttonIcon={
                    false ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={
                    "!bg-slate-200 !text-slate-600 border border-slate-300"
                  }
                  functions={handelCancel}
                  buttonHasLink={false}
                  buttonDisabled={false}
                />
                <Button
                  buttonType={"button"}
                  buttonIcon={
                    loading
                      ? "fa-light fa-spinner fa-spin"
                      : "fa-light fa-check"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={"Save"}
                  functions={handelSubmit}
                  buttonHasLink={false}
                  buttonDisabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteState: state.siteLogo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    siteLogo: (logo) => dispatch(siteSettingsImage(logo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
