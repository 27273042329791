import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";
import { useSelector } from "react-redux";
// import DropDownCheckBoxWithNameValue from "../form/DropDownCheckBoxWithNameValue";
import SelectWithSearch from "../form/SelectWithSearch";
import Checkbox from "../form/Checkbox";

const AddEditUsersModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = "ASC",
}) => {

  const companyList = useSelector((state) => state.companyListData.companyList);

  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [idCode, setIdCode] = useState("");
  const [selectValues, setSelectValues] = useState('')
  const [selectValueFromDropDown, setSelectValueFromDropDown] = useState("")
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false)


  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      setEmail(editData.email);
      setPhone(editData.phone);
      setLastName(editData.last_name);
      setIdCode(editData.id_code);
      setSelectValues(editData.company_id)
      // setPassword(editData.password)
    }
  }, [editData]);


  const closeModal = () => {
    setShow(false);
    setName("");
    setEmail("");
    setPhone("");
    setPassword("")
    setLastName("");
    setIdCode("");
    setDisabled(false);
    setEditData({});
    setSelectValues("")
    setSendWelcomeEmail(false)
  };

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(`user/${editData?._id ? "edit" : "add"}`, {
      // table_name: 'user',
      password: password,
      name: name,
      company_id: selectValueFromDropDown._id,
      last_name: lastName,
      email: email,
      phone: phone,
      type: "user",
      id_code: idCode,
      id: editData?._id || null,
      sendWelcomeEmail,
      ordering: editData?._id ? editData.ordering : list.length,
    });

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  /* const updateHandler = (id, checked) => {
    setSelectValues((companyList) =>
      companyList?.map((it) => {
        if (it?._id === id) {
          return {
            ...it,
            isEnabled: checked,
          };
        } else {
          return it;
        }
      })
    );
    setSelectValues((companiesList) => {
      if (checked) {
        return [...companiesList, id];
      } else {
        return companiesList.filter((item) => item !== id);
      }
    });
  } */


  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
      modalSize={"xl"}
    >
      <div className="relative w-full">
        <div className="grid sm:grid-cols-2 gap-x-4">
          <div className="relative w-full mb-3">
            <Input
              label={"First Name"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Add First Name"}
              inputValue={name || ""}
              setInput={setName}
            />
          </div>
          <div className="relative w-full mb-3">
            <Input
              label={"Last Name"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Add Last Name"}
              inputValue={lastName || ""}
              setInput={setLastName}
            />
          </div>
          <div className="relative w-full mb-3">
            <Input
              label={"Email"}
              labelClasses={"!text-xs"}
              inputType={"email"}
              inputPlaceholder={"Add Email"}
              inputValue={email || ""}
              setInput={setEmail}
            />
          </div>
          <div className="relative w-full mb-3">
            <Input
              label={"Phone"}
              labelClasses={"!text-xs"}
              inputType={"number"}
              inputPlaceholder={"Add Phone No."}
              inputValue={phone || ""}
              setInput={setPhone}
            />
          </div>
          <div className="relative w-full mb-3">
            <Input
              label={"Id Code"}
              labelClasses={"!text-xs"}
              inputType={"number"}
              inputPlaceholder={"Add Id Code"}
              inputValue={idCode || ""}
              setInput={setIdCode}
            />
          </div>
          <div className="relative w-full mb-3">
            <div className="w-full">
              <SelectWithSearch
                label={"Select Company"}
                labelClasses={"!text-xs"}
                dropdownData={[
                  { _id: '', name: 'Select Company' },
                  ...companyList
                ]}
                getSelectedValue={(value) => setSelectValueFromDropDown(value)}
                selectedValue={selectValues}
              />
            </div>
          </div>
          <div className="relative w-full mb-3 mt-2">
            <div className="w-full">
              <Checkbox 
                checkboxLabel="Send Welcome E-Mail"
                isChecked={sendWelcomeEmail}
                onClick={() => setSendWelcomeEmail(!sendWelcomeEmail)}
              />
            </div>
          </div>
        </div>
        
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditUsersModal;
