import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Table from '../components/elements/table/Table';
import TableAlt from '../components/elements/table/TableAlt';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';
// import { convertTimezone, formatDate } from '../helpers';
import toastr from 'toastr';
import { API_URL } from '../config/host';

import { setTitle } from '../helpers/MetaTag';

import AddEditCourseGuide from '../components/modals/AddEditCourseGuide';

const CourseGuides = () => {
  setTitle('Radisol | Course Guides');
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');


  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });

  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: 'Active', type: 'checkbox', isChecked: false ,value:1 },
    { _id: 2, name: 'Inactive', type: 'checkbox', isChecked: false,value:0 },
  ]);
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);


  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.name = { $regex: keyword, $options: 'i' };
        }
        if(statusFilterData.filter((item)=>item.touched&&item.isChecked).length) {
          searchParams.status={$in:statusFilterData.filter((item)=>item.touched &&item.isChecked).map((item)=>item.value)}
         
      }

        const res = await postData('crud/list', {
          table_name: 'courseGuide',
          queryParams: {
            deleted_at: null,
            ...searchParams,
          },
          selectParams: {
          description:true,
          status: true,
          name:true,
          created_by: true,
          ordering: true,
            image: {
              $cond: [
                { $arrayElemAt: [ '$image.path', 0 ] },
                {
                  $concat: [API_URL,{ $arrayElemAt: [ '$image.path', 0 ] },]
                },
                null
              ]
            }
          },
          join: [
            {
              toTable: "files",
              fromField: "image_id",
              toField: "_id",
              selectField: "image",
              projection: {
                path: true,
              },
            },

          ],
          sortQuery: sortField && sortType ? { [sortField] : sortType } : { ordering: 1 },
          limit: limit,
          offset: offset,
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [user?._id, keyword, limit, offset, sortField, sortType,statusFilterData.filter((item)=>item.touched&&item.isChecked).length]);
  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: 'image',
      name: 'image',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: 'name',
      name: 'name',
      align: 'left',
      sortable: true,
      filterable: false,
    },

    {
      _id: 3,
      width: 15,
      title: 'description',
      name: 'description',
      align: 'left',
      sortable: false,
      filterable: false,
    },


    {
      _id: 5,
      width: 15,
      title: 'Status',
      name: 'status',
      align: 'left',
      sortable: true,
      filterable: true,
        filterOptions: statusFilterData,
      onFilterChange: (id) => {
   
        setStatusFilterData(data => data.map(item => item._id !== id ? item : {
          ...item,
          isChecked: !item.isChecked,
          touched:true,
        }))
      },
    },
    {
      _id: 6,
      action: true,
    },
  ];

  

  const changeStatus = async (id) => {
    const res = await postData('crud/statusChange', {
      table_name: 'courseGuide',
      id: [id],
      column_name: 'status',
      column_value: !(
        list.data.find((item) => item._id === id).status || false
      ),
    });

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status: !(
                  list.data.find((item) => item._id === id).status || false
                ),
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'button',
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick: changeStatus,
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: '/crud/delete',
      deletePayload: { id: [item._id], table_name: 'courseGuide' },
    },
  ];

  const onDrag = async (data = []) => {
    const res = await postData('crud/ordering', {
      table_name: 'courseGuide',
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
    });

    if (res.status !== 200) {
      toastr.error(res.message);
    } else {
      toastr.success(res.message);
    }
  };
 
  return (
    <>
      <ListLayout
        title='Course Guides'
        addName='Add '
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={true}
          onDrag={onDrag}
          functions={changeStatus}
        />
      </ListLayout>
      <AddEditCourseGuide
        show={showModal}
        setShow={setShowModal}
        title={
          Object.keys(editData).length > 0
            ? 'Edit Course Guide'
            : 'Add Course Guide'
        }
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default CourseGuides;
