import React from 'react'
import ModalContainer from '../../containers/ModalContainer';

const ViewDetailsModal = ({ isOpen, selectedObj, onClose, modalSize }) => {
  return (
    <ModalContainer show={isOpen} title={selectedObj?.title} closeModal={onClose} modalSize={modalSize} >
      <div className='flex flex-col justify-start p-3 gap-5'>
        <div className='flex flex-row justify-start gap-5'>
          <div className='justify-start'>
            <p>{"Name"}</p>
          </div>
          <div className=''>
            <p>{selectedObj?.name}</p>
          </div>
        </div>

        <div className='flex flex-row justify-start gap-5'>
          <div className='justify-start'>
            <p>{"Company name"}</p>
          </div>
          <div className=''>
            <p>{selectedObj?.company}</p>
          </div>
        </div>
        <div className='flex flex-row justify-start gap-5'>
          <div className='justify-start'>
            <p>{"Ordered course"}</p>
          </div>
          <div className=''>
            {
              (selectedObj && Array.isArray(selectedObj?.course)) ? (selectedObj.course.map((item) => (
                <p key={item?._id}>
                  {item?.title["en"]}
                </p>
              ))) : <></>
            }
          </div>
        </div>
        <div className='flex flex-row justify-start gap-5'>
          <div className='justify-start'>
            <p>{"Total participants"}</p>
          </div>
          <div className=''>
            <p>{selectedObj?.participants}</p>
          </div>
        </div>

        <div className='flex flex-row items-center gap-5'>
          <div className=''>
            <p>{"Email"}</p>
          </div>
          <div className=''>
            <p>{selectedObj?.email}</p>
          </div>
        </div>
        <div className='flex flex-row items-center gap-5'>
          <div className=''>
            <p>{"Message"}</p>
          </div>
          <div className=''>
            <p>{selectedObj?.message}</p>
          </div>
        </div>
      </div>
    </ModalContainer>
  )
}

export default ViewDetailsModal;