import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import toastr from "toastr";
import AddEditAdminModal from "../components/modals/AddEditAdminModal";
import AddEditCompaniesModal from "../components/modals/AddEditCompaniesModal";
import AddEditUsersModal from "../components/modals/AddEditUserModal";
import { useDispatch } from "react-redux";
import { courseListData, listData, userListData, userTabChange } from "../store/companiesSlice";
import { API_URL } from '../config/host';
import { setTitle } from "../helpers/MetaTag";
import { formatDate } from "../helpers";
import { PDFDownloadLink } from '@react-pdf/renderer';
import ViewPdf from "./ViewPdf";


const CourseCompleted = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const tabChangeStatus = useSelector((state) => state.companyListData.tabChange);
  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: 'Active', type: 'checkbox', value: 1, isChecked: false },
    { _id: 2, name: 'Inactive', type: 'checkbox', value: 2, isChecked: false },
  ]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState({})
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,

  });
  const [tableList, setTableList] = useState([])
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [pageType, setPageType] = useState("");
  const [isReset, setIsReset] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);

  const [courseList, setCourseList] = useState({
    loading: true,
    data: [],
    count: 0,
  });

  const langs = useSelector((state) => state?.lang?.list.data);
  const defaultLang = (langs || []).find((item) => item.default)?.code || 'en';

  setTitle(`Radisol | Completed Courses`);

  useEffect(() => {
    if (user?._id) {
      let type = "";
      if (window.location.pathname === "/users") {
        type = "user";
        dispatch(userTabChange())
      } else if (window.location.pathname === "/admin") {
        type = "admin";
        dispatch(userTabChange())
      } else if (window.location.pathname === "/companies") {
        type = "company";
        dispatch(userTabChange())
      }
      setPageType(type)

      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.name = { $regex: keyword, $options: "i" };
        }
        const checkedStatusFilter = statusFilterData.filter((id) => id.isChecked)
        if (checkedStatusFilter.length > 0) {
          searchParams.status = checkedStatusFilter.map((item) => item.value)
        }

        const res = await postData("/admin/course/list", {
          ...searchParams,
          type: type,
          keyword: keyword,
          limit,
          offset,
          courseIds: selectedCourse,
          sortQuery:
            sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res?.data,
            loading: false,
            count: res.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [
    user?._id,
    keyword,
    limit,
    offset,
    sortField,
    sortType,
    window.location.pathname,
    statusFilterData,
    pageType,
    isReset,
    selectedCourse
  ]);

  useEffect(() => {
    setOffset(0)
    setLimit(10)
  }, [pageType])

  const sendConfirmationEmail = async (course) => {
    try {
      const res = await postData(`/admin/course/${course?.courseDetails?._id}/user/${course?.userDetails?._id}/completion-mail/send`)

    } catch (err) {
      toastr.error(err.message)
    }
  }

  useEffect(() => {

    setTableList(list.data.map((item) => ({
      ...item, ...item?.courseDetails, ...item?.userDetails,
      name: item?.userDetails?.first_name ? `${item?.userDetails?.first_name} ${item?.userDetails?.last_name}` : item?.userDetails.name,
      course_title: item?.courseDetails.title["ee"],
      created_at: formatDate(item.created_at, "MM-DD-YYYY"),
      action_button_download: ''
    })));
  }, [list?.data.length, Object.keys(list?.data?.courseDetails ?? []).length, Object.keys(list?.data?.userDetails ?? []).length]);


  // only company list add in redux store

  useEffect(() => {
    const loadData = async () => {
      const res = await postData("user/list", {
        type: "company",
        status: true,
      });
      if (res.status === 200) {
        dispatch(listData(res.data))
      } else {
      }
    };

    loadData();
  }, [tabChangeStatus, dispatch])

  // only user list add in redux store

  useEffect(() => {
    const loadData = async () => {
      const res = await postData("user/list", {
        type: "user",
        status: true
      });
      if (res.status === 200) {
        dispatch(userListData(res.data))
      } else {
      }
    };

    loadData();
  }, [tabChangeStatus, dispatch])

  // course list hitting===========>
  useEffect(() => {
    if (user?._id) {
      setCourseList((list) => ({ ...list, loading: true }));
      const searchParams = {}
      if (keyword) {
        searchParams.title = { $regex: keyword, $options: 'i' }
      }

      const loadData = async () => {
        const res = await postData('crud/list', {
          table_name: 'course',
          dateRange,
          queryParams: {
            deleted_at: null,
            status: true,
          },
          selectParams: {
            title: true,
            sub_title: true,
            description: true,
            image: {
              $cond: [
                '$image', { $concat: [API_URL, '$image'] }, null
              ]
            },
            ordering: true,
            created_at: true,
            category_details: {
              $cond: [
                { $arrayElemAt: ["$category_details", 0] }, { $arrayElemAt: ["$category_details", 0] }, {}
              ]
            },
          },
          join: [
            {
              toTable: "categories",
              fromField: "category_id",
              toField: "_id",
              selectField: "category_details",
              projection: {
                name: true, status: true
              }
            },
          ],
          sortQuery: sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },

        });
        if (res.status === 200) {
          setCourseList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
          dispatch(courseListData(res.data.list))
        } else {
        }
      };

      loadData();
    }
  }, [user?._id, tabChangeStatus, dispatch]);


  // change status==============>

  const changeStatus = async (id) => {
    const res = await postData('user/status/manage', {
      id: id
    });

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
              ...item,
              status: !(
                list.data.find((item) => item._id === id).status || false
              ),
            }
        ),
      }));
      toastr.success(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick: changeStatus,
    },
    location.pathname === "/users" &&
    {
      name: "Preview",
      icon: "fa-eye",
      type: "link",
      link: "/user-details",
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: "user/delete",
      deletePayload: { id: item._id },
      standout: true
    },
  ];

  // const onDrag = async (data = []) => {
  //   const res = await postData("crud/ordering", {
  //     table_name: "users",
  //     ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
  //   });

  //   if (res.status !== 200) {
  //     toastr.error(res.message);
  //   }
  // };

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: "Email",
      name: "email",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: "Course",
      name: "course_title",
      align: "left",
      sortable: true,
      filterable: true,
      filterOptions: courseList.data ? courseList.data.map(item => ({ ...item, type: 'checkbox', name: item.title.en, isChecked: selectedCourse.includes(item._id) })) : [],
      onFilterChange: (value) => {
        setSelectedCourse((preState) => {
          if (preState.includes(value)) {
            return preState.filter((item) => item !== value)
          } else {
            return [...preState, value]
          }
        });
      },
    },
    {
      _id: 4,
      width: 15,
      title: "Completed Date",
      name: "created_at",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 5,
      width: 18,
      title: "download",
      name: "action_button_download",
      align: "left",
      sortable: false,
      filterable: false,
    }
  ];

  return (
    <>
      <ListLayout
        showAddButton={false}
        title='Course Completed'
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        totalCount={list.count}
        setOffset={setOffset}
        isSearchComponent={false}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={tableList}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          // actionData={actionData}
          setList={setList}
          isDraggable={false}
        // functions={changeStatus}
        // onDrag={onDrag}
        />
      </ListLayout>
      {/* {window.location.pathname === "/users" && (
        <AddEditUsersModal
          show={showModal}
          setShow={setShowModal}
          title={
            Object.keys(editData).length > 0 ? "Edit User" : "Add User"
          }
          list={list.data}
          setList={setList}
          editData={editData}
          setEditData={setEditData}
        />
      )} */}

      {/* {window.location.pathname === "/admin" && (
        <AddEditAdminModal
          show={showModal}
          setShow={setShowModal}
          title={Object.keys(editData).length > 0 ? "Edit Admin" : "Add Admin"}
          list={list.data}
          setList={setList}
          editData={editData}
          setEditData={setEditData}
        />
      )} */}
      {/* {window.location.pathname === "/companies" && (
        <AddEditCompaniesModal
          show={showModal}
          setIsReset={setIsReset}
          setShow={setShowModal}
          title={Object.keys(editData).length > 0 ? "Edit Company" : "Add Company"}
          list={list.data}
          setList={setList}
          editData={editData}
          setEditData={setEditData}
        />
      )} */}
    </>
  );
};

export default CourseCompleted;