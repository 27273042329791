import React, { useEffect, useState } from 'react'
import ListLayout from '../containers/ListLayout'
import TableAlt from '../components/elements/table/TableAlt'
import { useSelector } from 'react-redux'
import { postData } from '../services/api';
import toastr from 'toastr';
import { setTitle } from '../helpers/MetaTag'



const Quizeresult = () => {
  setTitle('Radisol | Quiz Result');

  const user = useSelector((state) => state.auth.user);
  // const [statusFilterData, setStatusFilterData] = useState([
  //   { _id: 1, name: 'Active', type: 'checkbox', isChecked: true },
  //   { _id: 2, name: 'Inactive', type: 'checkbox', isChecked: false }
  // ])


  // all state==============>
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [dateRange, ] = useState({})
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [userList, setUserList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [companyList, setCompanyList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [courseList, setCourseList] = useState({
    loading: true,
    data: [],
    count: 0,
  });

  const [selectedUser, setSelectedUser] = useState([])
  const [selectedCompany, setSelectedCompany] = useState([])
  const [selectedCourse, setSelectedCourse] = useState([])


  // quize result list development=============>

  useEffect(() => {
    if (user?._id) {
      quizResultListFetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, keyword, limit, offset, sortField, sortType, dateRange, selectedUser, selectedCompany, selectedCourse]);

  const quizResultListFetch = async () => {
    setList((list) => ({ ...list, loading: true }));
    if (keyword) {
      setOffset(0)
    }

    const res = await postData('course/quiz/results', {
      keyword,
      userIds: selectedUser,
      companyIds: selectedCompany,
      courseIds: selectedCourse,
      sortQuery: !sortField && !sortType ? { created_at: -1 } : { [sortField]: sortType },
      limit,
      offset
    });
    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: res.data,
        loading: false,
        count: res.count,
      }));
    } else {
      setList((list) => ({ ...list, loading: false }));
    }
  };

  // userList api call==============>

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setUserList((list) => ({ ...list, loading: true }));

        const res = await postData('/user/list', {
          type: "user",
          sortQuery: !sortField && !sortType ? { ordering: 1 } : { [sortField]: sortType },
        });
        if (res.status === 200) {
          setUserList((list) => ({
            ...list,
            data: res.data,
            loading: false,
            count: res.count,
          }));
        } else {
          setUserList((list) => ({ ...list, loading: false }));
        }
      };
      loadData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id])


  // company List api call==============>

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setCompanyList((list) => ({ ...list, loading: true }));

        const res = await postData('/user/list', {
          type: "company",
          sortQuery: !sortField && !sortType ? { ordering: 1 } : { [sortField]: sortType },
          
        });
        if (res.status === 200) {
          setCompanyList((list) => ({
            ...list,
            data: res.data,
            loading: false,
            count: res.count,
          }));
        } else {
          setCompanyList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id])


  // course list api call===========>

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setCourseList((list) => ({ ...list, loading: true }));
        const searchParams = {}
        if (keyword) {
          searchParams.title = { $regex: keyword, $options: 'i' }
        }

        const res = await postData('crud/list', {
          table_name: 'course',
          dateRange,
          queryParams: {
            deleted_at: null,
            ...searchParams
          },
          selectParams: {
            title: true,
            ordering: true,
            created_at: true,
          },
          sortQuery: sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
          
        });
        if (res.status === 200) {
          setCourseList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setCourseList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  // resetQuiz handler================>

  const resetQuiz = (item) => {
    if (user?._id) {
      const loadData = async () => {
        const res = await postData('course/quiz/reset', {
          user_id: item.user_id,
          course_id: item.course_id,
          curriculum_id: item.curriculum_id,
          curriculum_list_id: item.curriculum_list_id
        });
        if (res.status === 200) {
          toastr.success(res.message);
          await quizResultListFetch()
        } else {
          toastr.error(res.message);
        }
      };

      loadData();
    }
  }

  useEffect(() => { }, [user?._id, list])

  // table header==============>

  const tableHeadData = [
    {
      _id: 1,
      width: 20,
      title: 'User',
      name: 'user_name',
      align: 'left',
      sortable: true,
      filterOptions: userList.data ? userList.data.map(item => ({ ...item, type: 'checkbox', isChecked: selectedUser.includes(item._id) })) : [],
      filterable: true,
      onFilterChange: (value) => {
        setSelectedUser((preState) => {
          if (preState.includes(value)) {
            return preState.filter((item) => item !== value)
          } else {
            return [...preState, value]
          }
        });
      }
    },
    {
      _id: 2,
      width: 20,
      title: 'Email',
      name: 'user_email',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 20,
      title: 'Company',
      name: 'company_name',
      align: 'left',
      filterOptions: companyList.data ? companyList.data.map(item => ({ ...item, type: 'checkbox' , isChecked: selectedCompany.includes(item._id) })) : [],
      sortable: true,
      filterable: true,
      onFilterChange: (value) => {
        setSelectedCompany((preState) => {
          if (preState.includes(value)) {
            return preState.filter((item) => item !== value)
          } else {
            return [...preState, value]
          }
        });
      }
    },
    {
      _id: 4,
      width: 20,
      title: 'company Email',
      name: 'company_email',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 5,
      width: 30,
      title: 'course',
      name: 'course_title',
      align: 'left',
      sortable: true,
      filterOptions: courseList.data ? courseList.data.map(item => ({ ...item, type: 'checkbox', name: item.title.en , isChecked: selectedCourse.includes(item._id) })) : [],
      filterable: true,
      langVal: true,
      onFilterChange: (value) => {
        setSelectedCourse((preState) => {
          if (preState.includes(value)) {
            return preState.filter((item) => item !== value)
          } else {
            return [...preState, value]
          }
        });
      }
      // dataType: "object",
      // dataKey: "en",
    },
    {
      _id: 11,
      width: 10,
      title: 'Quiz Video',
      name: 'curriculum_video_name',
      align: 'left',
      sortable: true,
      filterable: false,
      langVal:true
    },
    {
      _id: 6,
      width: 10,
      title: 'Total Answers',
      name: 'totalAnswers',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 7,
      width: 10,
      title: 'Pass Mark',
      name: 'pass_mark',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 8,
      width: 10,
      title: 'Percentage',
      name: 'percentage',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 9,
      width: 10,
      title: 'Created At',
      name: 'created_at',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 10,
      action: true,
    }
  ];

  const actionData = (item) => [
    {
      name: item.has_certificate === true ? "" : "reset",
      icon: 'fa-rotate-right',
      type: 'button',
      actionType: 'reset',
      onClick: () => resetQuiz(item),
      disabled: item.has_certificate
    },
  ]

  return (
    <>
      <ListLayout
        showAddButton={false}
        title='Quiz Results'
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        totalCount={list.count}
        setOffset={setOffset}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
        />
      </ListLayout>
    </>
  )
}

export default Quizeresult