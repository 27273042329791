import { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import toastr from 'toastr';
import ListLayout from '../containers/ListLayout';
import TableAlt from '../components/elements/table/TableAlt';
import AddEditCurriculumVideoModal from '../components/modals/AddEditCurriculumVideoModal';
import { postData } from '../services/api';
import { API_URL } from '../config/host';

const ManageCurriculum = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [dateRange, setDateRange] = useState({});
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: 'Active', type: 'checkbox', isChecked: true },
    { _id: 2, name: 'Inactive', type: 'checkbox', isChecked: false },
  ]);
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});



  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams['name.en']= { $regex: keyword, $options: 'i' };
        }
        if (dateRange && dateRange.start && dateRange.end) {
          searchParams.created_at = {
            $gte: dateRange.start,
            $lte: dateRange.end
          }
        }
        const res = await postData('crud/list', {
          table_name: 'curriculumList',
          queryParams: {
            deleted_at: null,
            curriculum_id: id,
            ...searchParams,
          },
          join: [
            {
              toTable: 'files',
              fromField: 'video_id',
              toField: '_id',
              selectField: 'video',
              projection: {
                path: true,
              },
            },
          ],
          selectParams: {
            name: true,
            no_of_question: true,
            pass_mark: {
              $cond: [
                '$pass_mark', '$pass_mark', null
              ]
            },
            quiz: true,
            video_id: true,
            quiz_time: true,
            status: true,
            deleted_at: true,
            created_at: true,
            ordering: true,
            video: {
              $cond: [
                { $arrayElemAt: ['$video.path', 0] },
                {
                  $concat: [API_URL, { $arrayElemAt: ['$video.path', 0] }],
                },
                null,
              ],
            },
          },
          sortQuery:
            !sortField && !sortType
              ? { ordering: 1 }
              : { [sortField]: sortType },
          limit,
          offset,
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [user?._id, keyword, limit, offset, sortField, sortType, dateRange, id, editData]);


  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: 'Name',
      name: 'name',
      align: 'left',
      sortable: true,
      filterable: false,
      langVal: true
    },
    {
      _id: 2,
      width: 15,
      title: 'No of question',
      name: 'no_of_question',
      align: 'left',
      sortable: true,
      filterable: false
    },
    {
      _id: 3,
      width: 15,
      title: 'Quiz time',
      name: 'quiz_time',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      width: 15,
      title: 'Created At',
      name: 'created_at',
      align: 'left',
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange({
            start: e[0],
            end: e[1],
          });
        } else {
          setDateRange(e)
        }
      },
    },
    {
      _id: 5,
      width: 15,
      title: 'Status',
      name: 'status',
      align: 'left',
      sortable: true,
      filterable: true,
      filterOptions: statusFilterData,
      onFilterChange: (id) => {
        setStatusFilterData((data) =>
          data.map((item) =>
            item._id !== id
              ? item
              : {
                ...item,
                isChecked: !item.isChecked,
              }
          )
        );
      },
    },
    {
      _id: 6,
      action: true,
    },
  ];

  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'button',
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick: changeStatus,
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: '/crud/delete',
      deletePayload: { id: [item._id], table_name: 'curriculumList' },
      standout: true
    },
  ];

  const changeStatus = async (id) => {
    const res = await postData('crud/statusChange', {
      table_name: 'curriculumList',
      id: [id],
      column_name: 'status',
      column_value: !(
        list.data.find((item) => item._id === id).status || false
      ),
    });

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
              ...item,
              status: !(
                list.data.find((item) => item._id === id).status || false
              ),
            }
        ),
      }));
    } else {
      toastr.error(res.message);
    }
  };

  const onDrag = async (data = []) => {
    const res = await postData('crud/ordering', {
      table_name: 'curriculumList',
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
    });

    if (res.status !== 200) {
      toastr.error(res.message);
    }
  };

  return (
    <>
      <ListLayout
        title='Curriculum video list'
        addName='Add Video'
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        totalCount={list.count}
        setOffset={setOffset}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={true}
          onDrag={onDrag}
        />
      </ListLayout>
      <AddEditCurriculumVideoModal
        show={showModal}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? 'Edit Video' : 'Add Video'}
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default ManageCurriculum;
