import { useState, useEffect, Fragment, useRef } from "react";
import Input from "../components/form/Input";
import Textarea from "../components/form/Textarea";
import Select from "../components/form/Select";
import Image from "../components/elements/Image";
import ButtonFile from "../components/form/ButtonFile";
import Button from "../components/form/Button";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import toastr from "toastr";
import { postData } from "../services/api";
import { classNames, numberRegx, tinymceApiKey, tinyMceInit } from "../helpers";
import ListLayoutAlt from "../containers/ListLayoutAlt";
import TableAlt from "../components/elements/table/TableAlt";
import { useSelector } from "react-redux";
import AddEditCurriculumModal from "../components/modals/AddEditCurriculumModal";
import AddEditFaqModal from "../components/modals/AddEditFaqModal";
import { API_URL } from "../config/host";
import TabContainer from "../components/elements/TabContainer";
import { decodeHTML } from "../helpers";
import Checkbox from "../components/form/Checkbox";

const ManageCourse = () => {
  const { id } = useParams();
  const editorRef = useRef(null);
  const [active, setActive] = useState("Overview");
  const [details, setDetails] = useState({});
  const [disableCourseStatus, setDisableCourseStatus] = useState(false);
  const [, setCategories] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [selectTab, setSelectTab] = useState("overview");
  const [selectedLangTab, setSelectedLangTab] = useState("en");
  const [isOverview, setIsOverview] = useState(false);
  const [description, setDescription] = useState({});
  const [title, setTitle] = useState({});
  const [subTitle, setSubTitle] = useState({});
  const [, setCategoryId] = useState("");
  const [instructorId, setInstructorId] = useState("");
  const [, setTags] = useState([]);
  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState("");
  const [modalType, setModalType] = useState();
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [comingSoon, setComingSoon] = useState(false)
  // Curriculums
  const [curriculumsShowModal, setCurriculumsShowModal] = useState(false);
  const [curriculumEditData, setCurriculumEditData] = useState({});
  const [curriculumList, setCurriculumList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [curriculumLimit, setCurriculumLimit] = useState(10);
  const [curriculumOffset, setCurriculumOffset] = useState(0);
  const [curriculumKeyword, setCurriculumKeyword] = useState("");

  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState({});
  const [faqList, setFaqList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [, setReviewList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [, setCommentList] = useState({
    loading: true,
    data: [],
    count: 0,
  });

  /* const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: "Active", type: "checkbox", isChecked: true },subTitle
    { _id: 2, name: "Inactive", type: "checkbox", isChecked: false },
  ]); */

  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [video, setVideo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [langs, setLangs] = useState([]);
  // let files;

  const dropdownData = [
    {
      _id: 1,
      label: "Overview",
      icon: "fa-circle-info",
      link: "/",
      type: "button",
      current: selectTab && selectTab === "overview" ? true : false,
      code: "overview",
    },
    {
      _id: 2,
      label: "Curriculum",
      icon: "fa-video-camera",
      link: "/",
      type: "button",
      current: selectTab && selectTab === "curriculum" ? true : false,
      code: "curriculum",
    },
    // {
    //     _id: 3,
    //     label: "Instructor",
    //     icon: "fa-user",
    //     link: "/",
    //     type: "button",
    //     current: selectTab && selectTab === "instructor" ? true : false,
    //     code: 'instructor'
    // },
    // {
    //   _id: 4,
    //   label: "Reviews",
    //   icon: "fa-star",
    //   link: "/",
    //   type: "button",
    //   current: selectTab && selectTab === "reviews" ? true : false,
    //   code: "reviews",
    // },
    {
      _id: 5,
      label: "FAQs",
      icon: "fa-question-circle",
      link: "/",
      type: "button",
      current: selectTab && selectTab === "faqs" ? true : false,
      code: "faqs",
    },
    // {
    //   _id: 6,
    //   label: "Comment",
    //   icon: "fa-comment",
    //   link: "/",
    //   type: "button",
    //   current: selectTab && selectTab === "comment" ? true : false,
    //   code: "comment",
    // },
  ];

  const formatLangData = langs.map((lang) => ({
    _id: lang._id,
    label: lang.code.toUpperCase(),
    icon: "fa-language",
    link: "/",
    type: "button",
    current: selectedLangTab && selectedLangTab === lang.code,
    code: lang.code,
  }));

  const changeTab = async (code) => {
    setSelectTab(code);
  };

  const onDrag = async (data = []) => {
    const res = await postData("crud/ordering", {
      table_name: "course",
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
    });

    if (res.status !== 200) {
      toastr.error(res.message);
    }
  };

  const onImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const allowedImageTypes = ["image/jpg", "image/jpeg", "image/png"];

      if (!allowedImageTypes.includes(file.type)) {
        toastr.error("File is not a valid image.");
        return;
      }
      setImage(file);
    }
  };

  useEffect(() => {
    loadDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadDetails = async () => {
    try {
      const result = await postData("crud/list", {
        table_name: "course",
        queryParams: {
          deleted_at: null,
          _id: id,
        },
        selectParams: {
          title: true,
          sub_title: true,
          description: true,
          status: true,
          category_id: true,
          instructor_id: true,
          tags: true,
          // image: {
          //   $cond: [
          //     '$image', { $concat: [ API_URL, '$image' ] }, null
          //   ]
          // },
          ordering: true,
          created_at: true,
          instructor_details: true,
          image_details: true,
          show_curriculum: true,
          show_faq: true,
          price: true,
          discount: true,
          image: {
            $cond: [
              { $arrayElemAt: ["$image_details", 0] },
              {
                $concat: [
                  API_URL,
                  { $arrayElemAt: ["$image_details.path", 0] },
                ],
              },
              null,
            ],
          },
          video: {
            $cond: [
              { $arrayElemAt: ["$video", 0] },
              {
                $concat: [API_URL, { $arrayElemAt: ["$video.path", 0] }],
              },
              null,
            ],
          },
          video_id: true,
          image_id: true,
          coming_soon: true
        },
        join: [
          {
            toTable: "instructors",
            fromField: "instructor_id",
            toField: "_id",
            selectField: "instructor_details",
            projection: {
              name: true,
              last_name: true,
              email: true,
              designation: true,
              description: true,
              website: true,
              social_links: true,
              status: true,
            },
          },
          {
            toTable: "files",
            fromField: "image_id",
            toField: "_id",
            selectField: "image_details",
            projection: {
              name: true,
              type: true,
              mine_type: true,
              size: true,
              path: true,
            },
          },
          {
            toTable: "files",
            fromField: "video_id",
            toField: "_id",
            selectField: "video",
            projection: {
              path: true,
            },
          },
        ],
      });
      if (result.data) {
        setDetails(result.data.list[0]);
      }
    } catch (err) {
      console.log(err.message);
    }
  };


  useEffect(() => {
    if (details?.title) {
      setTitle(details?.title);
    }

    if (details?.sub_title) {
      setSubTitle(details?.sub_title);
    }

    setCategoryId(details?.category_id);
    setInstructorId(details?.instructor_id);

    if (details?.description) {
      setDescription(details?.description);
    }

    if (details.tags && details.tags.length > 0) {
      setTags((tags) =>
        tags.map((item) => {
          let key = details.tags.findIndex((itm) => itm._id === item?._id);
          if (key !== -1) {
            return {
              ...item,
              isEnabled: details.tags[key].isEnabled,
            };
          } else {
            return {
              ...item,
            };
          }
        })
      );
    }

    if (details?.price) {
      setPrice(details?.price || 0);
    }

    if (details?.discount) {
      setDiscount(details?.discount || 0)
    }

    setComingSoon(details?.coming_soon || false)

    setShowImage(details.image);
    setVideo(details.video);

    /* const loadVideo = async () => {
      let showImage = await postData('file/get',{id: details?.image_id});
      if(showImage.status === 200){          
        files = new File([showImage.data], showImage.data.name, showImage.data.blob.mine_type);
        console.log(URL.createObjectURL(files));
        setShowImage(URL.createObjectURL(files));
      }        
    }
    loadVideo();    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?._id]);

  // add or edit overview data===========>

  const onOverview = async (e) => {
    setIsOverview(true);
    try {
      let imageId = undefined;
      if (image && image instanceof File) {
        const formData = new FormData();
        formData.append("file", image);

        if (details?.image_id) {
          formData.append("id", details.image_id);
        }

        const imgRes = await postData(
          "file/upload",
          formData,
          "multipart/form-data"
        );
        if (imgRes.status === 200) {
          imageId = imgRes.data?._id;
        } else {
          toastr.error(imgRes.message);
        }
      }

      let videoId = undefined;

      if (video && video instanceof File) {
        const formData = new FormData();
        formData.append("file", video);
        formData.append("private", true);

        if (details?.video_id) {
          formData.append("id", details?.video_id);
        }

        const vidRes = await postData(
          "file/upload",
          formData,
          "multipart/form-data"
        );
        if (vidRes.status === 200) {
          videoId = vidRes.data?._id;
        } else {
          toastr.error(vidRes.message);
        }
      }

      const result = await postData("crud/edit", {
        table_name: "course",
        data: {
          title: title,
          sub_title: subTitle,
          description: description,
          category_id: null, //categoryId,
          instructor_id: instructorId,
          tags: null, //tags,
          image_id: imageId,
          video_id: videoId,
          id: id,
          price: price || 0,
          discount: discount || 0,
          coming_soon: comingSoon
        },
        hasLang: true,
        langVals: ["title", "sub_title", "description"],
      });
      if (result.status && result.status === 200) {
        loadDetails();
        toastr.success(result.message);
        setIsOverview(false);
      } else {
        toastr.error(result.message);
        setIsOverview(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsOverview(false);
    }
  };

  useEffect(() => {
    const loadCategories = async () => {
      const res = await postData("category/list");
      let cat = [];
      Array.isArray(res.data.list) &&
        res.data.list.forEach((item, index) => {
          cat.push({ _id: item._id, label: item.name, value: item._id });
        });
      setCategories(cat);
    };
    loadCategories();

    const loadInstructors = async () => {
      const res = await postData("crud/list", {
        table_name: "instructor",
        queryParams: {
          deleted_at: null,
        },
        selectParams: {
          name: true,
          email: true,
        },
      });
      let ins = [];
      Array.isArray(res.data.list) &&
        res.data.list.forEach((item, index) => {
          ins.push({ _id: item._id, label: item.name, value: item._id });
        });
      setInstructors(ins);
    };
    loadInstructors();

    const loadTags = async () => {
      const res = await postData("crud/list", {
        table_name: "tag",
        queryParams: {
          deleted_at: null,
        },
        selectParams: {
          tag_name: true,
          status: true,
        },
      });
      Array.isArray(res.data.list) &&
        setTags(
          res.data.list.map((item, index) => {
            return {
              _id: item._id,
              label: item.tag_name,
              value: item._id,
              isEnabled: false,
            };
          })
        );
    };
    loadTags();
  }, []);

  useEffect(() => {
    const loadLangs = async () => {
      const res = await postData("lang/list", {});
      if (res.status === 200) {
        setLangs(res.data);

        const defaultLang = res.data.find((item) => item.default);
        if (defaultLang) {
          setSelectedLangTab(defaultLang.code);
        }
      }
    };

    loadLangs();
  }, []);

  /* const updateHandler = async (id, checked) => {
    setTags(
      tags.map((it) => {
        if (it?._id === id) {
          return {
            ...it,
            isEnabled: checked,
          };
        } else {
          return it;
        }
      })
    );
  }; */

  // ======================================>

  // Curriculums
  const tableCurriculumsHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
      langVal: true,
    },
    {
      _id: 2,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange({
            start: e[0],
            end: e[1],
          });
        } else {
          setDateRange(e);
        }
      },
    },
    {
      _id: 3,
      width: 15,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      title: "Action",
      action: true,
    },
  ];

  const actionCurriculumsData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setCurriculumsShowModal(true);
        setCurriculumEditData(
          curriculumList.data.find((item) => item._id === id) || {}
        );
      },
    },
    {
      name: "Video List",
      icon: "fa-list",
      type: "link",
      link: "/curriculums",
      // onClick: (id) => {
      //   setCurriculumEditData(curriculumList.data.find((item) => item._id === id) || {});
      // },
    },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: changeCurriculumsStatus,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: "/crud/delete",
      deletePayload: { id: [item._id], table_name: "curriculum" },
      standout: true,
    },
  ];

  const changeCurriculumsStatus = async (id) => {
    const res = await postData("crud/statusChange", {
      table_name: "curriculum",
      id: [id],
      column_name: "status",
      column_value: !(
        curriculumList.data.find((item) => item._id === id).status || false
      ),
    });

    if (res.status === 200) {
      setCurriculumList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,

                status: !(
                  list.data.find((item) => item._id === id).status || false
                ),
              }
        ),
      }));
    } else {
      toastr.error(res.message);
    }
  };

  useEffect(() => {
    if (user?._id) {
      const loadCurriculumData = async () => {
        setCurriculumList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (curriculumKeyword) {
          searchParams[`name.${selectedLangTab || "en"}`] = {
            $regex: curriculumKeyword,
            $options: "i",
          };
        }
        if (dateRange && dateRange.start && dateRange.end) {
          searchParams.created_at = {
            $gte: dateRange.start,
            $lte: dateRange.end,
          };
        }
        const res = await postData("crud/list", {
          table_name: "curriculum",
          queryParams: {
            deleted_at: null,
            course_id: id,
            ...searchParams,
          },
          selectParams: {
            name: true,
            status: true,
            ordering: true,
            course_id: true,
            deleted_at: true,
            created_at: true,
          },
          curriculumLimit,
          curriculumOffset,
          sortQuery:
            !sortField && !sortType
              ? { ordering: 1 }
              : { [sortField]: sortType },
        });
        if (res.status === 200) {
          setCurriculumList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setCurriculumList((list) => ({ ...list, loading: false }));
        }
      };
      loadCurriculumData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?._id,
    curriculumKeyword,
    curriculumLimit,
    curriculumOffset,
    sortField,
    sortType,
    selectedLangTab,
    dateRange,
  ]);

  // ============FAQ List=============>

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setFaqList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.question = { $regex: keyword, $options: "i" };
        }
        if (dateRange && dateRange.start && dateRange.end) {
          searchParams.created_at = {
            $gte: dateRange.start,
            $lte: dateRange.end,
          };
        }
        const res = await postData("crud/list", {
          table_name: "faq",
          queryParams: {
            deleted_at: null,
            course_id: id,
            ...searchParams,
          },
          selectParams: {
            question: true,
            answer: true,
            status: true,
            course_id: true,
            deleted_at: true,
            created_at: true,
            ordering: true,
          },
          sortQuery:
            !sortField && !sortType
              ? { ordering: 1 }
              : { [sortField]: sortType },
          limit,
          offset,
        });
        if (res.status === 200) {
          setFaqList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setFaqList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, keyword, limit, offset, sortField, sortType, dateRange]);

  const changeStatus = async (id) => {
    const res = await postData("crud/statusChange", {
      table_name: "faq",
      id: [id],
      column_name: "status",
      column_value: !(
        faqList.data.find((item) => item._id === id).status || false
      ),
    });

    if (res.status === 200) {
      setFaqList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,

                status: !(
                  list.data.find((item) => item._id === id).status || false
                ),
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const onDragFaq = async (data = []) => {
    const res = await postData("crud/ordering", {
      table_name: "faq",
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
    });

    if (res.status !== 200) {
      toastr.error(res.message);
    } else {
      toastr.success(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(faqList.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: changeStatus,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: "/crud/delete",
      deletePayload: { id: [item._id], table_name: "faq" },
      standout: true,
    },
  ];

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Question",
      name: "question",
      align: "left",
      sortable: true,
      filterable: false,
      langVal: true,
    },
    {
      _id: 2,
      width: 15,
      title: "Answer",
      name: "answer",
      align: "left",
      sortable: true,
      filterable: false,
      langVal: true,
      html: true,
    },
    {
      _id: 4,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange({
            start: e[0],
            end: e[1],
          });
        } else {
          setDateRange(e);
        }
      },
    },
    {
      _id: 5,
      width: 15,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 6,
      title: "Action",
      action: true,
    },
  ];

  // =================faq end===========>

  // ================Review list============>
  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setReviewList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.rating = { $regex: keyword, $options: "i" };
        }
        const res = await postData("crud/list", {
          table_name: "review",
          queryParams: {
            deleted_at: null,
            course_id: id,
            ...searchParams,
          },
          selectParams: {
            user: true,
            review_details: true,
            rating: true,
            comment: true,
            user_id: true,
            course_id: true,
            status: true,
            created_at: true,
          },
          join: [
            {
              toTable: "users",
              fromField: "user_id",
              toField: "_id",
              selectField: "user",
              justOne: true,
              projection: {
                name: true,
                image: true,
              },
            },
            {
              toTable: "review_reactions",
              fromField: "_id",
              toField: "review_id",
              selectField: "review_details",
              justOne: true,
              projection: {
                type: true,
              },
            },
          ],
          sortQuery:
            !sortField && !sortType
              ? { ordering: 1 }
              : { [sortField]: sortType },
          limit,
          offset,
        });
        if (res.status === 200) {
          setReviewList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setReviewList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, keyword, limit, offset, sortField, sortType]);

  /* const actionReviewData = (item) => [
    // {
    //   name: "Edit",
    //   icon: "fa-pen-to-square",
    //   type: "button",
    //   onClick: (id) => {
    //     setShowModal(true);
    //     setEditData(reviewList.data.find((item) => item._id === id) || {});
    //   },
    // },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: changeStatus,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: "/crud/delete",
      deletePayload: { id: [item._id], table_name: "review" },
    },
  ]; */

  // ============reviewList end==========>

  // ==========comment list==========>

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setCommentList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.rating = { $regex: keyword, $options: "i" };
        }

        const res = await postData("crud/list", {
          table_name: "comment",
          queryParams: {
            deleted_at: null,
            course_id: id,
            ...searchParams,
          },
          selectParams: {
            user: true,
            review_details: true,
            comment: true,
            user_id: true,
            course_id: true,
            status: true,
            created_at: true,
          },
          join: [
            {
              toTable: "users",
              fromField: "user_id",
              toField: "_id",
              selectField: "user",
              justOne: true,
              projection: {
                name: true,
                image: true,
              },
            },
            {
              toTable: "review_reactions",
              fromField: "_id",
              toField: "review_id",
              selectField: "review_details",
              justOne: true,
              projection: {
                type: true,
              },
            },
          ],
          sortQuery:
            !sortField && !sortType
              ? { ordering: 1 }
              : { [sortField]: sortType },
          limit,
          offset,
        });
        if (res.status === 200) {
          setCommentList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setCommentList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, keyword, limit, offset, sortField, sortType]);

  /* const actionCommentData = (item) => [
    // {
    //   name: "Edit",
    //   icon: "fa-pen-to-square",
    //   type: "button",
    //   onClick: (id) => {
    //     setShowModal(true);
    //     setEditData(reviewList.data.find((item) => item._id === id) || {});
    //   },
    // },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: changeStatus,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: "/crud/delete",
      deletePayload: { id: [item._id], table_name: "review" },
    },
  ]; */

  const onVideoChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const allowedVideoTypes = [
        "video/x-flv",
        "video/mp4",
        "application/x-mpegURL",
        "video/MP2T",
        "video/3gpp",
        "video/quicktime",
        "video/x-msvideo",
        "video/x-ms-wmv",
      ];

      if (!allowedVideoTypes.includes(file.type)) {
        toastr.error("File is not a valid video.");
        return;
      }
      setVideo(file);
    }
  };

  const updateCurruculumFaq = async (type = "curriculum", value = true) => {
    setDisableCourseStatus(true);

    try {
      await postData(`course/${type}/status/update/${details._id}`, {
        [`show_${type}`]: value,
      });
      setDetails((details) => ({
        ...details,
        [`show_${type}`]: value,
      }));
      toastr.success("Successfully updated course.");
    } catch (err) {
      toastr.error(err.message);
    } finally {
      setDisableCourseStatus(false);
    }
  };

  return (
    <>
      <div className="relative d-flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">
          {details?.title ? details?.title[selectedLangTab] : ""}
        </div>
        <div className="text-base text-slate-800">
          {details?.sub_title ? details?.sub_title[selectedLangTab] : ""}
        </div>
      </div>
      <div className="relative ml-auto justify-start items-center">
        <TabContainer
          active={active}
          changeTab={changeTab}
          setActive={setActive}
          tabs={dropdownData}
        />

        {dropdownData.map((item, index) => (
          <Fragment key={index}>
            {selectTab && selectTab === "overview" && (
              <div
                className={`${!item?.current ? "hidden" : ""}`}
                id="overview"
                role="tabpanel"
                aria-labelledby="overview-tab"
              >
                {active === "Overview" && (
                  <TabContainer
                    setActive={(val) => setSelectedLangTab(val.toLowerCase())}
                    active={selectedLangTab}
                    changeTab={setSelectedLangTab}
                    tabs={formatLangData}
                  />
                )}
                <div className="relative grid grid-cols-4 gap-4">
                  <div className="col-span-3">
                    <div className="relative bg-white rounded shadow divide-y divide-slate-200">
                      <div className="py-8 px-6 flex flex-col">
                        <div className="space-y-4 mt-4">
                          <div className="flex items-start gap-4">
                            <div className="relative w-full">
                              <Input
                                label={"Course Name"}
                                labelClasses={"!text-xs"}
                                inputType={"text"}
                                inputPlaceholder={"Enter Course Name"}
                                inputValue={title[selectedLangTab] || ""}
                                setInput={(val) =>
                                  setTitle((data) => ({
                                    ...data,
                                    [selectedLangTab]: val,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="flex items-start gap-4">
                            <div className="relative w-full">
                              <Textarea
                                label={"Course Short Description"}
                                labelClasses={"!text-xs"}
                                inputType={"text"}
                                inputPlaceholder={"Enter Course Description"}
                                inputValue={subTitle[selectedLangTab] || ""}
                                setTextarea={(val) =>
                                  setSubTitle((data) => ({
                                    ...data,
                                    [selectedLangTab]: val,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="flex items-start gap-4">
                            <div className="relative w-full">
                              <Editor
                                apiKey={tinymceApiKey()}
                                onInit={(evt, editor) =>
                                  (editorRef.current = editor)
                                }
                                value={
                                  decodeHTML(description[selectedLangTab]) || ""
                                }
                                init={tinyMceInit()}
                                onEditorChange={(newText) =>
                                  setDescription((data) => ({
                                    ...data,
                                    [selectedLangTab]: newText,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="py-3 px-3 border-t border-slate-200">
                        <div className="flex items-center justify-end gap-4">
                          <Button
                            buttonClasses="!font-semibold !text-xs !leading-tight !uppercase !h-10 !-slate-400 !border-b-2 !border-transparent !whitespace-nowrap"
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={
                              isOverview
                                ? "fa-light fa-spinner fa-spin"
                                : "fa-light fa-check"
                            }
                            buttonIconPosition={"left"}
                            buttonLabel={"Save"}
                            functions={onOverview}
                            buttonHasLink={false}
                            buttonDisabled={isOverview}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="sticky top-12 bg-white rounded shadow">
                      <div className="mb-3 p-4">
                        {(showImage ||
                          (image !== null && image instanceof File)) && (
                          <div className="relative w-full">
                            <div
                              className="cursor-pointer bg-white text-rose-500 w-6 h-6 flex justify-center items-center absolute rounded-full shadow -top-2 -right-2"
                              onClick={() => {
                                setImage(null);
                                setShowImage(null);
                              }}
                            >
                              <i className="fa-regular fa-fw fa-xmark"></i>
                            </div>
                            <div className="h-30 w-full rounded-md mb-2 overflow-hidden border border-slate-100">
                              <Image
                                src={
                                  image ? URL.createObjectURL(image) : showImage
                                }
                                alt={title}
                              />
                            </div>
                          </div>
                        )}
                        <ButtonFile
                          accepts="image/*"
                          functions={onImageChange}
                          buttonLabel="Upload Image"
                          buttonIconPosition="left"
                          buttonIcon="fa-arrow-up-from-bracket"
                        />
                      </div>
                      <div className="mb-3 p-4">
                        {/* Video */}
                        <div className="relative w-full">
                          {video && (
                            <div className="h-30 w-full rounded-md mb-2 overflow-hidden border border-slate-100">
                              <video
                                className="w-full h-[176px]"
                                controls
                                src={
                                  video instanceof File
                                    ? URL.createObjectURL(video)
                                    : video
                                }
                              ></video>
                            </div>
                          )}
                        </div>
                        <ButtonFile
                          accepts="video/*"
                          functions={onVideoChange}
                          buttonLabel="Upload Video"
                          buttonIconPosition="left"
                          buttonIcon="fa-arrow-up-from-bracket"
                        />
                      </div>
                      {/* <div className="mb-3">
                        <Select
                          xPlacement={"left"}
                          dropdownButtonClass={"!bg-white"}
                          selectedValue={categoryId}
                          dropdownData={categories}
                          getSelectedValue={(obj) => setCategoryId(obj._id)}
                          label={"Select Category"}
                        />
                      </div> */}
                      <div className="p-4">
                        <div className="flex items-center">
                          <Checkbox 
                            isChecked={comingSoon}
                            checkboxLabel="Coming Soon"
                            checkboxName="coming_soon"
                            onClick={() => setComingSoon(cur => !cur)}
                          />
                        </div>
                      </div>
                      
                      <div className="mb-3 p-4">
                        <div class="font-medium text-slate-500 mb-1 !text-xs">
                          Course Price
                        </div>
                        <div className="flex items-center">
                          <span className="border border-slate-300 h-10 w-10 flex items-center justify-center rounded-s">
                            €
                          </span>
                          <span className="flex justify-center items-center w-full">
                            <Input
                              inputClasses={"w-full rounded-none rounded-e"}
                              inputValue={price || ""}
                              setInput={(val) =>
                                setPrice(numberRegx.test(val) && val)
                              }
                            />
                          </span>
                        </div>
                      </div>
                      <div className="mb-3 p-4">
                        <div class="font-medium text-slate-500 mb-1 !text-xs">
                          Course Discount
                        </div>
                        <div className="flex items-center">
                          <span className="border border-slate-300 h-10 w-10 flex items-center justify-center rounded-s">
                            %
                          </span>
                          <span className="flex justify-center items-center w-full">
                            <Input
                              inputClasses={"w-full rounded-none rounded-e"}
                              inputType="number"
                              inputValue={discount || ""}
                              setInput={(val) =>
                                setDiscount(numberRegx.test(val) && val)
                              }
                            />
                          </span>
                        </div>
                      </div>
                      <div className="mb-3 p-4">
                        {instructorId?.length !== 0 ? (
                          <Select
                            xPlacement={"left"}
                            dropdownButtonClass={"!bg-white"}
                            dropdownData={instructors}
                            selectedValue={instructorId}
                            getSelectedValue={(obj) => setInstructorId(obj._id)}
                            label={"Select Instructor"}
                          />
                        ) : (
                          <Select
                            xPlacement={"left"}
                            dropdownButtonClass={"!bg-white"}
                            dropdownData={[
                              { _id: "", label: "Select Company" },
                              ...instructors,
                            ]}
                            selectedValue={instructorId}
                            getSelectedValue={(obj) => setInstructorId(obj._id)}
                            label={"Select Instructor"}
                          />
                        )}
                      </div>
                      {/* <div className="mb-3">
                        <div className="text-sm font-medium text-slate-500 mb-1">
                          Tags
                        </div>
                        <div className="relative w-full">
                          <DropdownCheckbox
                            data={tags}
                            tags={tags}
                            functions={(id, event) => updateHandler(id, event)}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectTab && selectTab === "curriculum" && (
              <div
                className={`${!item?.current ? "hidden" : ""} `}
                id="curriculum"
                role="tabpanel"
                aria-labelledby="curriculum-tab"
              >
                <div className="flex mb-3">
                  <div
                    className={classNames(
                      "text-[17px] font-medium leading-tight rounded py-1 px-2",
                      details?.show_curriculum
                        ? "bg-green-100 text-green-600"
                        : "bg-rose-100 text-rose-600",
                      disableCourseStatus
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    )}
                    onClick={() =>
                      updateCurruculumFaq(
                        "curriculum",
                        !details.show_curriculum
                      )
                    }
                  >
                    {details?.show_curriculum ? "Active" : "Inactive"}
                  </div>
                </div>
                <ListLayoutAlt
                  title="Curriculums"
                  addName="Add Curriculum"
                  onAdd={() => setCurriculumsShowModal(true)}
                  limit={curriculumLimit}
                  setLimit={setCurriculumLimit}
                  setOffset={setCurriculumOffset}
                  keyword={curriculumKeyword}
                  setKeyword={setCurriculumKeyword}
                  noOfPage={Math.ceil(curriculumList.count / curriculumLimit)}
                  onPageChange={(val) =>
                    setCurriculumOffset(val.selected * curriculumLimit)
                  }
                  totalCount={curriculumList.count}
                >
                  <TableAlt
                    tableHeadData={tableCurriculumsHeadData}
                    tableData={curriculumList.data}
                    loading={curriculumList.loading}
                    sortField={sortField}
                    sortType={sortType}
                    setSortField={setSortField}
                    setSortType={setSortType}
                    actionData={actionCurriculumsData}
                    setList={setCurriculumList}
                    hasAction={true}
                    isDraggable={true}
                    onDrag={onDrag}
                    functions={changeStatus}
                  />
                </ListLayoutAlt>
              </div>
            )}
            {selectTab && selectTab === "instructor" && (
              <div
                className={`${
                  !item?.current ? "hidden" : ""
                } p-4 rounded-lg bg-gray-50 dark:bg-gray-800`}
                id="instructor"
                role="tabpanel"
                aria-labelledby="instructor-tab"
              >
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  This is some placeholder content the{" "}
                  <strong className="font-medium text-gray-800 dark:text-white">
                    Settings tab's associated content
                  </strong>
                  . Clicking another tab will toggle the visibility of this one
                  for the next. The tab JavaScript swaps classes to control the
                  content visibility and styling.
                </p>
              </div>
            )}
            {/* {selectTab && selectTab === "reviews" && (
              <div
                className={`${!item?.current ? "hidden" : ""
                  } p-4 rounded-lg bg-gray-50`}
                id="reviews"
                role="tabpanel"
                aria-labelledby="reviews-tab"
              >
                <div className="p-4">
                  <ListLayoutAlt
                    title="Reviews"
                    addName=""
                    showAddButton={false}
                    onAdd={() => setShowModal(true)}
                    limit={limit}
                    setLimit={setLimit}
                    setOffset={setOffset}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    noOfPage={Math.ceil(faqList.count / limit)}
                    onPageChange={(val) => setOffset(val.selected * limit)}
                    totalCount={faqList.count}
                    isSearchComponent={false}
                  >

                    {!reviewList.loading && reviewList?.data?.length <= 0 && (
                      <div className="py-4">
                        <NoDataFound label={'No Data Found'} />
                      </div>
                    )}

                    {
                      reviewList?.data?.map((list) =>
                        <ReviewList
                          key={list._id}
                          comment={list.comment}
                          rating={list.rating || 0}
                          user={list.user}
                          created_at={list.created_at}
                          item={list}
                          actionData={actionReviewData}
                          reviewReactions={list.review_details}
                          hasAction={false} />
                      )
                    }
                  </ListLayoutAlt>
                </div>
              </div>
            )} */}
            {selectTab && selectTab === "faqs" && (
              <div
                className={`${!item?.current ? "hidden" : ""}`}
                id="faqs"
                role="tabpanel"
                aria-labelledby="faqs-tab"
              >
                <div className="flex mb-3">
                  <div
                    className={classNames(
                      "text-[17px] font-medium leading-tight rounded py-1 px-2",
                      details?.show_faq
                        ? "bg-green-100 text-green-600"
                        : "bg-rose-100 text-rose-600",
                      disableCourseStatus
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    )}
                    onClick={() =>
                      updateCurruculumFaq("faq", !details.show_faq)
                    }
                  >
                    {details?.show_faq ? "Active" : "Inactive"}
                  </div>
                </div>
                <ListLayoutAlt
                  title="FAQs"
                  addName="Add FAQs"
                  onAdd={() => setShowModal(true)}
                  limit={limit}
                  setLimit={setLimit}
                  setOffset={setOffset}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  noOfPage={Math.ceil(faqList.count / limit)}
                  onPageChange={(val) => setOffset(val.selected * limit)}
                  totalCount={faqList.count}
                >
                  <TableAlt
                    tableHeadData={tableHeadData}
                    tableData={faqList.data}
                    loading={faqList.loading}
                    sortField={sortField}
                    sortType={sortType}
                    setSortField={setSortField}
                    setSortType={setSortType}
                    actionData={actionData}
                    setList={setFaqList}
                    hasAction={true}
                    isDraggable={true}
                    onDrag={onDragFaq}
                    functions={changeStatus}
                  />
                </ListLayoutAlt>
              </div>
            )}
            {/* {selectTab && selectTab === "comment" && (
              <div
                className={`${!item?.current ? "hidden" : ""
                  } p-4 rounded-lg bg-gray-50`}
                id="comment"
                role="tabpanel"
                aria-labelledby="comment-tab"
              >

                <div className="p-4">
                  <ListLayoutAlt
                    title="Comments"
                    addName=""
                    showAddButton={false}
                    onAdd={() => setShowModal(true)}
                    limit={limit}
                    setLimit={setLimit}
                    setOffset={setOffset}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    noOfPage={Math.ceil(faqList.count / limit)}
                    onPageChange={(val) => setOffset(val.selected * limit)}
                    totalCount={faqList.count}
                    isSearchComponent={false}
                  >

                    {
                      commentList?.data?.map((list) =>
                        <CommentList
                          key={list._id}
                          comment={list.comment}
                          user={list.user}
                          created_at={list.created_at}
                          item={list}
                          reviewReactions={list.review_details}
                          actionData={actionReviewData}
                          hasAction={false} />
                      )
                    }
                  </ListLayoutAlt>
                </div>
              </div>
            )} */}
          </Fragment>
        ))}
      </div>

      <AddEditFaqModal
        show={showModal}
        apiKey={tinymceApiKey()}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? "Edit FAQ" : "Add FAQ"}
        list={faqList.data}
        setList={setFaqList}
        editData={editData}
        setEditData={setEditData}
        id={id}
        langs={formatLangData}
      />

      <AddEditCurriculumModal
        show={curriculumsShowModal}
        apiKey={tinymceApiKey()}
        setShow={setCurriculumsShowModal}
        title={
          Object.keys(curriculumEditData).length > 0
            ? "Edit Curriculum"
            : "Add Curriculum"
        }
        list={curriculumList.data}
        setList={setCurriculumList}
        editData={curriculumEditData}
        setEditData={setCurriculumEditData}
        id={id}
      />
    </>
  );
};

export default ManageCourse;
