import { useState, useEffect } from 'react';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import Input from '../form/Input';
// import Textarea from '../form/Textarea';
import { postData } from '../../services/api';
// import { convertToBase64Async } from '../../helpers';
import toastr from 'toastr';
import TabContainer from '../elements/TabContainer';

const AddEditCurriculumModal = ({
  title = '',
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = 'ASC',
  id,
  apiKey
}) => {
  // const editorRef = useRef(null);
  const [disabled, setDisabled] = useState()
  const [name, setName] = useState({});
  const [langs, setLangs] = useState([])
  const [selectedLangTab, setSelectedLangTab] = useState('en')

  useEffect(() => {
    const loadLangs = async () => {
      const res = await postData('lang/list', {})
      if (res.status === 200) {
        setLangs(res.data)
        
        const defaultLang = res.data.find(item => item.default)
        if (defaultLang) {
          setSelectedLangTab(defaultLang.code)
        }
      }
    }

    loadLangs()
  }, [ ])


  useEffect(() => {
    if (editData?._id) {
      if (editData.name) {
        typeof editData.name === 'string' ? setName({ en: editData.name }) : setName(editData.name)
      }
    }
  }, [editData])

  const formatLangData = langs.map(lang => ({
    _id: lang._id,
    label: lang.code.toUpperCase(),
    icon: "fa-language",
    link: "/",
    type: "button",
    current: selectedLangTab && selectedLangTab === lang.code,
    code: lang.code
  }))

  const closeModal = () => {
    setShow(false);
    setName({});    
    setDisabled(false)
    setEditData({})
    setSelectedLangTab('en')
  };
  

  const onSubmit = async () => {
    setDisabled(true)  
    const res = await postData(`crud/${editData?._id ? 'edit' : 'add'}`, {
      table_name: 'curriculum',
      data: {
        name: name,
        course_id: id,
        id: editData?._id || null,
        ordering: editData?._id ? editData.ordering : list.length
      },
      hasLang: true,
      langVals: ['name'],
      validationRules: {
        'data.name': 'required|nonEmptyObject',
      },
      validationMessages: {
        'required.data.name': 'Name Field is required',
        'nonEmptyObject.data.name': 'Name Field is required',
      }
    })

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList(list => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1
          }))
        } else {
          setList(list => ({
            ...list,
            data: [res.data, ...list.data,],
            count: list.count + 1
          }))
        }
      } else if (editData && editData?._id) {
        setList(list => ({
          ...list,
          data: list.data.map(item => item._id !== editData._id ? item : res.data)
        }))
      }

      closeModal()
      toastr.success(res.message)
    } else {
      setDisabled(false)
      toastr.error(res.message)
    }
  }



  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <TabContainer 
        tabs={formatLangData} 
        active={selectedLangTab}
        setActive={(val) => setSelectedLangTab(val.toLowerCase())}
        changeTab={setSelectedLangTab}
      />
      <div className='flex justify-between w-full'>
        <div className='relative w-full'>
          <div className="mb-3">
          <Input
            label={'Name'}
            labelClasses={'!text-xs'}
            inputType={'text'}
            inputPlaceholder={'Enter Name'}
            inputValue={name[selectedLangTab] || ''}
            setInput={(val) => setName(data => ({
              ...data,
              [selectedLangTab]: val
            }))}
          />
          </div>          
        </div>

      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditCurriculumModal;
