import { useState, useEffect, useRef, useMemo } from "react";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import Input from "../form/Input";
import Textarea from "../form/Textarea";
import Image from "../elements/Image";
import Select from "../form/Select";
// import Checkbox from '../form/Checkbox';
import { Editor } from "@tinymce/tinymce-react";
import ButtonFile from "../form/ButtonFile";
import { postData } from "../../services/api";
// import { convertToBase64Async } from '../../helpers';
import toastr from "toastr";
import { numberRegx, tinymceApiKey, tinyMceInit } from "../../helpers";
import TabContainer from "../elements/TabContainer";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";
// import DropdownCheckbox from '../form/DropdownCheckbox';

const AddEditCourseModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  list = [],
  setList = () => {},
  addType = "ASC",
}) => {
  const editorRef = useRef(null);
  const [disabled, setDisabled] = useState();
  const [categories, setCategories] = useState([]);
  const [instructors, setInstructors] = useState([]);
  // const [tagData,setTagData] = useState([]);
  const [name, setName] = useState({});
  const [shortDesc, setShortDesc] = useState({});
  const [description, setDescription] = useState({});
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [categoryId] = useState(null);
  const [instructorId, setInstructorId] = useState("");
  const [tags, setTags] = useState(null);
  const [langs, setLangs] = useState([]);
  const [selectedLangTab, setSelectedLangTab] = useState("en");
  const [showProgressBar, setProgressBarVisibility] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState();
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [comingSoon, setComingSoon] = useState(false)

  // const [formValues, setFormValues] = useState([
  //   {
  //     ingredient: "",
  //     qty: "",
  //   },
  // ]);

  // const handleChange = (i, e) => {
  //   let newFormValues = [...formValues];
  //   newFormValues[i][e.target.name] = e.target.value;
  //   setFormValues(newFormValues);
  // };

  // const handleSelect = (i, e) => {
  //   let newFormValues = [...formValues];
  //   newFormValues[i]["ingredient"] = e;
  //   setFormValues(newFormValues);
  // };

  useEffect(() => {
    if (editData?._id) {
      setName(editData.title);
      setDescription(editData.description);
      setShortDesc(editData?.sub_title);
    }
  }, [editData]);

  useEffect(() => {
    const loadLangs = async () => {
      const res = await postData("lang/list", {});
      if (res.status === 200) {
        setLangs(res.data);

        const defaultLang = res.data.find((item) => item.default);
        if (defaultLang) {
          setSelectedLangTab(defaultLang.code);
        }
      }
    };

    loadLangs();
  }, []);

  const closeModal = () => {
    setShow(false);
    setName({});
    setDescription({});
    setShortDesc({});
    setDisabled(false);
    setEditData({});
    setSelectedLangTab("en");
    setUploadPercentage(0);
    setProgressBarVisibility(false);
    setImage(null);
    setVideo(null);
  };

  const onImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const allowedImageTypes = ["image/jpg", "image/jpeg", "image/png"];

      if (!allowedImageTypes.includes(file.type)) {
        toastr.error("File is not a valid image.");
        return;
      }
      setImage(file);
    }
  };
  const onVideoChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const allowedVideoTypes = [
        "video/x-flv",
        "video/mp4",
        "application/x-mpegURL",
        "video/MP2T",
        "video/3gpp",
        "video/quicktime",
        "video/x-msvideo",
        "video/x-ms-wmv",
      ];

      if (!allowedVideoTypes.includes(file.type)) {
        toastr.error("File is not a valid video.");
        return;
      }
      setVideo(file);
    }
  };

  const onSubmit = async () => {
    setDisabled(true);
    setUploadPercentage(0);

    let imageId = null;
    if (image) {
      //base64 = await convertToBase64Async(image);
      const formData = new FormData();
      formData.append("file", image);
      const imgRes = await postData(
        "file/upload",
        formData,
        "multipart/form-data"
      );
      if (imgRes.status === 200) {
        imageId = imgRes.data?._id;
      } else {
        setDisabled(false);
        toastr.error(imgRes.message);
      }
    }

    let videoId = undefined;

    if (video && video instanceof File) {
      setProgressBarVisibility(true);
      const formData = new FormData();
      formData.append("file", video);
      const vidRes = await postData(
        "file/upload",
        formData,
        "multipart/form-data",
        setUploadPercentage
      );
      if (vidRes.status === 200) {
        videoId = vidRes.data?._id;
        setUploadPercentage(100);
        setProgressBarVisibility(false);
      } else {
        setProgressBarVisibility(false);
        toastr.error(vidRes.message);
      }
    }

    setUploadPercentage(0);

    const res = await postData(`crud/${editData?._id ? "edit" : "add"}`, {
      table_name: "course",
      data: {
        title: name,
        description: description,
        sub_title: shortDesc,
        id: editData?._id || null,
        category_id: categoryId,
        instructor_id: instructorId,
        tags: tags,
        image_id: imageId,
        video_id: videoId,
        price: price || 0,
        discount: discount || 0,
        coming_soon: comingSoon
      },
      hasLang: true,
      langVals: ["title", "sub_title", "description"],
      validationRules: {
        "data.title": "required",
        //"data.category_id": "required",
        "data.instructor_id": "required",
        "data.sub_title": "required",
        "data.description": "required",
      },
      validationMessages: {
        "required.data.title": "Title Field is required",
        //"required.data.category_id": "Category Field is required",
        "required.data.instructor_id": "Instructor Field is required",
        "data.sub_title": "Short Description is required",
        "data.description": "Long Description is required",
      },
    });

    const formatCourseResData = (data) => {
      return {
        ...data,
        category_details: {
          _id: categoryId,
          name: categories.find((item) => item._id === categoryId)?.label || "",
        },
      };
    };

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, formatCourseResData(res.data)],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [formatCourseResData(res.data), ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : formatCourseResData(res.data)
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  useEffect(() => {
    const loadCategories = async () => {
      const res = await postData("category/list");
      let cat = [];
      Array.isArray(res.data.list) &&
        res.data.list.forEach((item, index) => {
          cat.push({ _id: item._id, label: item.name, value: item._id });
        });
      setCategories(cat);
    };
    loadCategories();

    const loadInstructors = async () => {
      const res = await postData("crud/list", {
        table_name: "instructor",
        queryParams: {
          deleted_at: null,
        },
        selectParams: {
          name: true,
          email: true,
        },
      });
      let ins = [{ _id: "", label: "Select", value: "" }];
      Array.isArray(res.data.list) &&
        res.data.list.forEach((item, index) => {
          ins.push({ _id: item._id, label: item.name, value: item._id });
        });
      setInstructors(ins);
    };
    loadInstructors();

    const loadTags = async () => {
      const res = await postData("crud/list", {
        table_name: "tag",
        queryParams: {
          deleted_at: null,
        },
        selectParams: {
          tag_name: true,
          status: true,
        },
      });
      // setTagData(
      //   res.data.list.map((item, index) => {
      //     return {
      //       _id: item._id,
      //       label: item.tag_name,
      //       value: item._id,
      //       isEnabled: false
      //     }
      //   })
      // );
      Array.isArray(res.data.list) &&
        setTags(
          res.data.list.map((item, index) => {
            return {
              _id: item._id,
              label: item.tag_name,
              value: item._id,
              isEnabled: false,
            };
          })
        );
    };
    loadTags();
  }, []);

  const formatLangData = langs.map((lang) => ({
    _id: lang._id,
    label: lang.code.toUpperCase(),
    icon: "fa-language",
    link: "/",
    type: "button",
    current: selectedLangTab && selectedLangTab === lang.code,
    code: lang.code,
  }));

  // const updateHandler = async (id,checked) => {
  //     console.log(id,checked)
  //     setTags(
  //         tags.map((it) => {
  //           if(it?._id === id){
  //             return {
  //               ...it,
  //               isEnabled:checked,
  //             }
  //           } else {
  //             return it;
  //           }
  //         })
  //     );
  // }

  const renderVideo = useMemo(
    () => (
      <>
        <video
          className="w-full h-[176px]"
          preload="auto"
          controls
          src={video instanceof File && URL.createObjectURL(video)}
        ></video>
      </>
    ),
    [video]
  );

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xxxxl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <TabContainer
        tabs={formatLangData}
        active={selectedLangTab}
        setActive={(val) => setSelectedLangTab(val.toLowerCase())}
        changeTab={setSelectedLangTab}
      />
      <div className="flex justify-between w-full">
        <div className="relative w-full">
          <div className="mb-3">
            <Input
              label={"Course Name"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Course Name"}
              inputValue={name[selectedLangTab] || ""}
              setInput={(val) =>
                setName((data) => ({
                  ...data,
                  [selectedLangTab]: val,
                }))
              }
            />
          </div>

          <div className="mb-3">
            <Textarea
              label={"Course Short Description"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Course Description"}
              inputValue={shortDesc[selectedLangTab] || ""}
              setTextarea={(val) =>
                setShortDesc((data) => ({
                  ...data,
                  [selectedLangTab]: val,
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Editor
              apiKey={tinymceApiKey()}
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={description[selectedLangTab] || ""}
              init={{ height: 300 }}
              onEditorChange={(newText) =>
                setDescription((data) => ({
                  ...data,
                  [selectedLangTab]: newText,
                }))
              }
            />
          </div>
        </div>
        <div className="w-[550px] ml-4 pl-4 border-l border-solid border-gray-200">
          <div className="relative mb-3">
            {image !== null && image instanceof File && (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden mb-3">
                <Button
                  buttonClasses={
                    "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-rose-500 !w-6 !h-6 !rounded-full !shadow !text-sm"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <Image
                  src={URL.createObjectURL(image)}
                  alt={name}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-fit"}
                />
              </div>
            )}
          </div>
          <div className="relative mb-3">
            <ButtonFile
              functions={onImageChange}
              buttonLabel="Upload Image"
              buttonIconPosition="left"
              buttonIcon="fa-arrow-up-from-bracket"
              accepts={"image/*"}
            />
          </div>
          <div className="relative mb-3">
            {video !== null && video instanceof File && (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden mb-3 min-h-[200px]">
                <Button
                  buttonClasses={
                    "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-rose-500 !w-6 !h-6 !rounded-full !shadow !text-sm"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setVideo(null)}
                />
                {/* <video className="w-full h-[176px]" controls src={URL.createObjectURL(video)}></video> */}
                {renderVideo}
              </div>
            )}
          </div>
          <div className="relative mb-3">
            <ButtonFile
              functions={onVideoChange}
              buttonLabel="Upload Video"
              buttonIconPosition="left"
              buttonIcon="fa-arrow-up-from-bracket"
              accepts={"video/*"}
            />
          </div>
          <div>
            {showProgressBar && (
              <div className="mb-3 pt-6">
                <div className="w-full bg-teal-500/25 rounded relative">
                  <div
                    className="bg-teal-500 text-[10px] font-medium text-white text-center leading-none rounded flex justify-center items-center h-4 relative transition-all duration-200"
                    style={{ width: uploadPercentage + "%" }}
                  >
                    {uploadPercentage > 0 && (
                      <span class="bg-teal-500 absolute -right-4 bottom-full mb-2 rounded-sm py-1 px-1 text-[10px] font-semibold text-white w-8 flex items-center justify-center">
                        <span class="bg-teal-500 absolute -bottom-1 left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-sm"></span>
                        {uploadPercentage}%
                      </span>
                    )}
                  </div>
                </div>
                <div class="font-medium text-slate-500 mt-1 text-xs text-center">
                  Uploading Video
                </div>
              </div>
            )}
          </div>
          <div className="my-3 py-4">
            <div className="flex items-center">
              <Checkbox 
                isChecked={comingSoon}
                checkboxLabel="Coming Soon"
                checkboxName="coming_soon"
                onClick={() => setComingSoon(cur => !cur)}
              />
            </div>
          </div>
          <div className="mb-3">
            <div class="font-medium text-slate-500 mb-1 !text-xs">
              Course Price
            </div>
            <div className="flex items-center">
              <span className="border border-slate-300 h-10 w-10 flex items-center justify-center rounded-s">
                €
              </span>
              <span className="flex justify-center items-center w-full">
                <Input
                  inputClasses={"w-full rounded-none rounded-e"}
                  inputValue={price || ""}
                  inputType="number"
                  setInput={(val) => setPrice(numberRegx.test(val) && val)}
                />
              </span>
            </div>
          </div>
          <div className="mb-3">
            <div class="font-medium text-slate-500 mb-1 !text-xs">
              Course Discount
            </div>
            <div className="flex items-center">
              <span className="border border-slate-300 h-10 w-10 flex items-center justify-center rounded-s">
                %
              </span>
              <span className="flex justify-center items-center w-full">
                <Input
                  inputClasses={"w-full rounded-none rounded-e"}
                  inputValue={discount || ""}
                  inputType="number"
                  setInput={(val) => setDiscount(numberRegx.test(val) && val)}
                />
              </span>
            </div>
          </div>
          <div className="mb-3">
            <Select
              xPlacement={"left"}
              dropdownButtonClass={"!bg-white"}
              selectedValue={instructorId}
              dropdownData={instructors}
              getSelectedValue={(obj) => setInstructorId(obj._id)}
              label={"Select Instructor"}
            />
          </div>
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditCourseModal;
