import moment from 'moment'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from '@react-pdf/renderer';
import bgImage from '../assets/images/certificate.png';
import source1 from "../assets/font/Arial.ttf";
import source2 from "../assets/font/Clarendon-Regular.ttf";
import source3 from "../assets/font/NeonBines.ttf";
import source4 from "../assets/font/Poppins-Light.ttf";
import source5 from "../assets/font/Poppins-Regular.ttf";
import source6 from "../assets/font/Poppins-SemiBold.ttf";

Font.register({ family: 'Arial', src: source1 });
Font.register({ family: 'Clarendon', src: source2 });
Font.register({ family: 'NeonBines', src: source3 });
Font.register({ family: 'PoppinsLight', src: source4 });
Font.register({ family: 'PoppinsRegular', src: source5 });
Font.register({ family: 'PoppinsSemiBold', src: source6 });

const styles = StyleSheet.create({
  page: {
    position: "relative",
    zIndex: 0,
    width: "100%",
    height: "100%",
    color: "#05455F",
    fontFamily: "PoppinsLight"
  },
  pageBg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: -1
  },
  pageBgImage: {
    width: "100%",
    height: "100%",
  },
  header: {
    width: "100%",
    textAlign: "center",
    padding: 20,
    position: "absolute",
    top: 0,
    left: 0
  },
  headerTitle: {
    fontSize: 44,
    fontFamily: "Clarendon",
    letterSpacing: 1,
    marginTop: 15,
    marginBottom: 5,
  },
  headerSubtitle: {
    fontSize: 18,
    fontFamily: "PoppinsRegular",
    letterSpacing: 1
  },
  certificateAward: {
    width: "100%",
    textAlign: "center",
    paddingHorizontal: 20,
    paddingTop: 180
  },
  awarderName: {
    color: "#3B9BA0",
    fontSize: 44,
    fontFamily: "NeonBines",
  },
  awarderDivider: {
    borderBottom: '1.5px solid #ccc',
    display: 'inline-block',
    width: '250px',
    marginTop: 18,
    marginBottom: 5,
    marginHorizontal: "auto"
  },
  awarderDateIssue: {
    fontSize: 14,
    letterSpacing: 1.2,
  },
  awarderId: {
    fontSize: 14,
    letterSpacing: 1.2,
    marginTop: 10,
  },
  course: {
    width: "100%",
    textAlign: "center",
    paddingHorizontal: 60,
    paddingTop: 20
  },
  courseTitle: {
    fontSize: 20,
    fontFamily: "Arial",
    textTransform: "uppercase",
    paddingTop: 10,
    letterSpacing: 1.4
  },
  courseInstructorName: {
    fontSize: 13.5,
    paddingTop: 15,
    letterSpacing: 0.5
  },
  courseInstructorDesignation: {
    fontSize: 13.5,
    paddingTop: 15,
    letterSpacing: 0.5
  },
  footer: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  footerImage: {
    position: "absolute",
    bottom: 5,
    left: 200,
    width: '120px',
    height: '66px',
    paddingVertical: 3
  },
  footerLogo: {
    width: "100%"
  },
  footerComp: {
    position: "absolute",
    bottom: 35,
    left: 268,
    paddingBottom: 8,
    borderBottom: '0.5px solid #3B9BA0',
  },
  footerReg: {
    position: "absolute",
    bottom: 35,
    left: 370,
    paddingBottom: 8,
    borderBottom: '0.5px solid #3B9BA0',
  },
  footerAddress: {
    position: "absolute",
    bottom: 35,
    left: 510,
    paddingBottom: 8,
    borderBottom: '0.5px solid #3B9BA0',
  },
  footerText: {
    fontSize: 14,
    letterSpacing: 0.5
  },
});

const ViewPdf = ({ course = {} }) => {
  return (
    <Document>
      <Page size={[842, 595]} style={styles.page}>
        <View style={styles.pageBg}>
          <Image src={bgImage} style={styles.pageBgImage} />
        </View>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>Tunnistus</Text>
          <Text style={styles.headerSubtitle}>nr&nbsp;{course.certificate_id || ''} | tõendab, et</Text>
        </View>
        <View style={styles.certificateAward}>
          <Text style={styles.awarderName}>
            {
              course?.userDetails?.is_company_owner ?
              (course?.userDetails?.first_name || '') + ' ' + (course?.userDetails?.last_name || '') :
              (course?.userDetails?.name || '') + ' ' + (course?.userDetails?.last_name || '')
            }
          </Text>
          <View style={styles.awarderDivider}></View>
          <Text style={styles.awarderId}>Isikukood {course?.userDetails?.id_code || 'N/A'}</Text>
          <Text style={styles.awarderDateIssue}>on läbinud {moment(new Date(course?.created_at)).format('DD.MM.YYYY')}</Text>
        </View>

        <View style={styles.course}>
          <Text style={styles.courseTitle}>Kiirgusohutuskoolituse kiirgustöötajatele</Text>
          <Text style={styles.courseInstructorName}>Koolitaja Mare Lintrop</Text>
          <Text style={styles.courseInstructorName}>7 täienduspunkti</Text>
          <Text style={styles.courseInstructorDesignation}>kiirguskoolitus.ee</Text>
        </View>
        <View style={styles.footer}>
          {/* <View style={styles.footerImage}>
            <Image style={styles.footerLogo} src={siteSettings?.logo ? new URL(siteSettings?.logo).href : NO_IMAGE} />
          </View> */}
          <View style={styles.footerComp}>
            <Text style={styles.footerText}>Radisol OÜ</Text>
          </View>
          <View style={styles.footerReg}>
            <Text style={styles.footerText}>Reg nr 16283164</Text>
          </View>
          <View style={styles.footerAddress}>
            <Text style={styles.footerText}>Sõbra 54/2, Tartu</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ViewPdf;
