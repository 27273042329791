import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Table from '../components/elements/table/Table';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';

import toastr from 'toastr';
import TableAlt from '../components/elements/table/TableAlt';
import { setTitle } from '../helpers/MetaTag';

const initialModalValue = {
  title: "View Details",
  name: "",
  email: "",
  message: "",
  company: "",
  course: [],
  participants: 0,
};

const Enquiry = () => {
  setTitle("Radisol | Enquiry");

  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedObj, setSelectedObj] = useState({
    ...initialModalValue
  });

  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: 'enquiry', type: 'checkbox', isChecked: false, value: 1 },
    { _id: 2, name: 'news later', type: 'checkbox', isChecked: false, value: 2 },
  ]);
  useEffect(() => {
    if (user?._id) {

      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {}
        if (keyword) {
          setOffset(0)
          setLimit(10)
          searchParams.keyword = keyword;
        }
        if (statusFilterData.filter((item) => item.touched && item.isChecked).length) {
          searchParams.type = { $in: statusFilterData.filter((item) => item.touched && item.isChecked).map((item) => item.value) }

        }

        const res = await postData('enquiry/list', {
          deleted_at: null,
          ...searchParams,
          sortQuery: sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
          limit: limit,
          offset: offset,
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.result,
            loading: false,
            count: res.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [user?._id, keyword, limit, offset, sortField, sortType, statusFilterData.filter((item) => item.touched && item.isChecked).length]);
  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: 'Name',
      name: 'name',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: 'Email',
      name: 'email',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: 'Message',
      name: 'message',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 5,
      width: 15,
      title: 'Type',
      name: 'type',
      align: 'left',
      sortable: true,
      filterable: true,
      filterOptions: statusFilterData,
      onFilterChange: (id) => {

        setStatusFilterData(data => data.map(item => item._id !== id ? item : {
          ...item,
          isChecked: !item.isChecked,
          touched: true,
        }))
      },
    },
    {
      _id: 4,
      width: 15,
      title: 'Created At',
      name: 'created_at',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      action: true,
    },
  ];

  const changeStatus = async (id) => {
    const res = await postData('crud/statusChange', {
      table_name: 'enquiry',
      id: [id],
      column_name: 'status',
      column_value: !(
        list.data.find((item) => item._id === id).status || false
      ),
    });

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
              ...item,
              status: !(
                list.data.find((item) => item._id === id).status ||
                false
              ),
            }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    // {
    //   name: 'Edit',
    //   icon: 'fa-pen-to-square',
    //   type: 'button',
    //   onClick: id => {
    //     setShowModal(true)
    //     setEditData(list.data.find(item => item._id === id) || {})
    //   }
    // },
    // {
    //   name: item.status ? 'Inactive' : 'Active',
    //   icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
    //   type: 'button',
    //   onClick: changeStatus,
    // },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: '/enquiry/delete',
      deletePayload: { id: item._id },
      standout: true
    },
    {
      name: 'View',
      icon: 'fa-eye',
      type: 'button',
      actionType: 'view',
      deleteLink: '/enquiry/view',
      deletePayload: { id: item._id },
      standout: false
    },
  ];

  const onDrag = async (data = []) => {
    // const res = await postData('crud/ordering', {
    //   table_name: 'enquiry',
    //   ordering_data: data.map((item, i) => ({ id: item._id, ordering: i }))
    // })

    // if (res.status !== 200) {
    //   toastr.error(res.message)
    // }   
  }

  return (
    <>
      <ListLayout
        title='Enquiry And Subscriptions'
        addName=''
        showAddButton={false}
        onAdd={() => false}
        limit={limit}
        keyword={keyword}
        setLimit={setLimit}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          hasAction={true}
          // isDraggable={true}
          // onDrag={onDrag}
          isCursorPointer={true}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleOnClick={(e) => {
            setIsOpen(prev => !prev);
            setSelectedObj({ ...selectedObj, message: e?.message, email: e?.email, name: e?.name, company: e?.company ?? "NA", course: e?.course ?? [], participants: e?.participants ?? null });
          }}
          initialModalValue={initialModalValue}
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
      </ListLayout>
    </>
  );
};

export default Enquiry;
