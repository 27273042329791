import { useState, useEffect } from 'react';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import Input from '../form/Input';
import Textarea from '../form/Textarea';
import Image from '../elements/Image';
import ButtonFile from '../form/ButtonFile';
import { postData } from '../../services/api';
import { API_URL } from '../../config/host';

import toastr from 'toastr';
import TabContainer from '../elements/TabContainer';
import Button from "../form/Button";




const AddEditTestimonialModal = ({
  title = '',
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = 'ASC'
}) => {


  const [disabled, setDisabled] = useState()
  const [name, setName] = useState({});
  const [description, setDescription] = useState({})
  const [image, setImage] = useState(null)
  const [langs, setLangs] = useState([])
  const [selectedLangTab, setSelectedLangTab] = useState('en')

  useEffect(() => {
    if (editData?._id) {
      if (editData.name) {
        typeof editData.name === "string" ? setName({ en: editData.name }) : setName(editData.name);
      }
      if (editData.description) {
        typeof editData.description === "string" ? setDescription({ en: editData.description }) : setDescription(editData.description);
      }
      setImage(editData.image)
    }
  }, [editData])

  const closeModal = () => {
    setShow(false);
    setName({})
    setDescription({})
    setImage(null)
    setDisabled(false)
    setEditData({})
  };

  const onImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const allowedImageTypes = ["image/jpg", "image/jpeg", "image/png",];

      if (!allowedImageTypes.includes(file.type)) {
        toastr.error("File is not a valid image.");
        return;
      }
      setImage(file);
    }
  };

  const onSubmit = async () => {
    setDisabled(true)
    let imageId = undefined
    if (image instanceof File) {

      const formData = new FormData()
      formData.append('file', image)
      const imgRes = await postData('file/upload', formData, 'multipart/form-data')
      if (imgRes.status === 200) {
        imageId = imgRes.data?._id;
      } else {
        setDisabled(false)
        toastr.error(imgRes.message)
      }
    }

    const res = await postData(`crud/${editData?._id ? 'edit' : 'add'}`, {
      table_name: 'testimonial',
      data: {
        name: name,
        description: description,

        id: editData?._id || null,
        image_id: imageId,
        ordering: true
      },
      hasLang: true,
      langVals: ['name', 'description'],
      validationRules: {
        'data.name': 'required',
        'data.description': 'required'
      },
      validationMessages: {
        'required.data.name': 'Name Field is required',
        'required.data.description': 'Description Field is required'
      }
    })

    if (res.status === 200) {
      let detailsRes = await postData('crud/list', {
        table_name: "testimonial",
        queryParams: {
          deleted_at: null,
          _id: res.data._id
        },
        selectParams: {
          name: true,
          description: true,
          status: true,
          created_at: true,
          ordering: true,
          image: {
            $cond: [
              { $arrayElemAt: ['$image.path', 0] },
              {
                $concat: [
                  API_URL,
                  { $arrayElemAt: ['$image.path', 0] },
                ]
              },
              null
            ]
          },

        },
        join: [
          {
            toTable: "files",
            fromField: "image_id",
            toField: "_id",
            selectField: "image",
            projection: {
              path: true,
            },
          },
        ],
        limit: 1

      })
      detailsRes = detailsRes.data.list[0]
      console.log(detailsRes, "detailsres")
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList(list => ({
            ...list,
            data: [...list.data, detailsRes],
            count: list.count + 1
          }))
        } else {
          setList(list => ({
            ...list,
            data: [detailsRes, ...list.data,],
            count: list.count + 1
          }))
        }
      } else if (editData && editData?._id) {
        setList(list => ({
          ...list,
          data: list.data.map(item => item._id !== editData._id ? item : detailsRes)
        }))
      }

      closeModal()

      toastr.success(res.message)
    } else {
      setDisabled(false)
      toastr.error(res.message)
    }
  }

  // =========data lang development===============>
  useEffect(() => {
    const loadLangs = async () => {
      const res = await postData('lang/list', {})
      if (res.status === 200) {
        setLangs(res.data)

        const defaultLang = res.data.find(item => item.default)
        if (defaultLang) {
          setSelectedLangTab(defaultLang.code)
        }
      }
    }

    loadLangs()
  }, [])

  const formatLangData = langs.map(lang => ({
    _id: lang._id,
    label: lang.code.toUpperCase(),
    icon: "fa-language",
    link: "/",
    type: "button",
    current: selectedLangTab && selectedLangTab === lang.code,
    code: lang.code
  }))


  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xxxl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <TabContainer
        tabs={formatLangData}
        active={selectedLangTab}
        setActive={(val) => setSelectedLangTab(val.toLowerCase())}
        changeTab={setSelectedLangTab}
      />
      <div className='flex justify-between w-full'>
        <div className='relative w-full'>
          <div className="mb-3">
            <Input
              label={'Name'}
              labelClasses={'!text-xs'}
              inputType={'text'}
              inputPlaceholder={'Enter Name'}
              inputValue={name[selectedLangTab] || ''}
              setInput={(val) => setName(data => ({ ...data, [selectedLangTab]: val }))}
            />
          </div>

          <div className="mb-3">
            <Textarea
              label={" Description"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter  Description"}
              inputValue={description[selectedLangTab] || ''}
              setTextarea={(val) => setDescription(data => ({ ...data, [selectedLangTab]: val }))}
            />
          </div>

        </div>

        <div className='w-[450px] ml-4 pl-4 border-l border-solid border-gray-200'>
          <div className='relative mb-3'>
            {
              image && (
                <div className="relative w-full rounded border border-slate-200 flex overflow-hidden mb-3">
                  <Button
                    buttonClasses={"!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-rose-500 !w-6 !h-6 !rounded-full !shadow !text-sm"}
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-xmark"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    functions={() => setImage(null)}
                  />
                  <Image
                    src={image instanceof File ? URL.createObjectURL(image) : image}
                    alt={name}
                    width={'100%'}
                    height={'100%'}
                    effect={'blur'}
                    classes={'object-cover'}
                  />
                </div>
              )}
          </div>
          <div className='relative mb-3'>
            <ButtonFile
              functions={onImageChange}
              buttonLabel="Upload Image"
              buttonIconPosition="left"
              buttonIcon="fa-arrow-up-from-bracket"
            />
          </div>
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditTestimonialModal;
